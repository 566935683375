import { css, cx, useTheme } from '@fable/theme'
import { FlexBox, Emoji, Text } from '@fable/components'
import { Reaction } from '@fable/types'

const ChatMessageEmoji = ({
  reaction,
  emojiSizes,
  onToggleReaction,
}: {
  reaction: Reaction
  emojiSizes: { height: string; width: string }
  onToggleReaction: (reaction: Reaction) => void
}) => {
  const { motion, colors, nativeOverrides } = useTheme()

  return (
    <button
      data-testid="emoji"
      className={cx(
        nativeOverrides.buttonClear,
        css`
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
            ${motion.transition('transform')};
          }
        `
      )}
      onClick={(e) => {
        e.stopPropagation()
        onToggleReaction(reaction)
      }}
    >
      <FlexBox alignItems="center">
        <FlexBox
          centerAll
          className={css`
            border-radius: 100%;
            width: ${emojiSizes.width};
            height: ${emojiSizes.height};
            border: 1px solid
              ${reaction.state ? colors.blackSwan : 'transparent'};
          `}
        >
          <Emoji
            className={css`
              width: inherit;
              height: inherit;
            `}
            emoji={reaction.content}
          />
        </FlexBox>
        {reaction.count > 0 && (
          <Text
            className={css`
              margin-left: 3px;
              color: ${reaction.state ? colors.blackSwan : colors.hurricane};
              font-family: inherit !important;
            `}
            type="label"
            sizing="M"
          >
            {reaction.count}
          </Text>
        )}
      </FlexBox>
    </button>
  )
}

export default ChatMessageEmoji
