import { css } from '@fable/theme'
import { Text } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'

const TaskHeader = ({ title, detail }: { title: string; detail?: string }) => {
  const { theme } = useTeams()
  return (
    <div
      className={css`
        text-align: center;
      `}
    >
      <Text
        styles={{
          display: 'block',
          marginBottom: '2px',
          fontSize: '20px',
          lineHeight: '28px',
        }}
        weight="bold"
        color={theme.siteVariables.bodyColor}
      >
        {title}
      </Text>
      {!!detail && <Text color={theme.siteVariables.bodyColor}>{detail}</Text>}
    </div>
  )
}

export default TaskHeader
