import { useCallback, useEffect, useRef, useState } from 'react'
import { css } from '@fable/theme'
import * as microsoftTeams from '@microsoft/teams-js'
import * as teamsFx from '@microsoft/teamsfx'
import { PublicClientApplication } from '@azure/msal-browser'
import { firebaseAuth } from '@fable/api'
import { reload } from '@firebase/auth'
import { useTeams } from 'hooks/useTeams'
import {
  Auth,
  OAuthProvider,
  signInWithPopup,
  unlink as firebaseUnlink,
} from 'firebase/auth'
import { signOutWithFirebase } from './Firebase'

const LONG_PRESS_TIMEOUT = 2000

export const Debug = () => {
  const [show, setShow] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()
  const { inTeams, context, theme } = useTeams()

  const toggleDebug = useCallback(() => {
    setShow(!show)
  }, [show])
  const touchStartHandler = useCallback(
    (e: TouchEvent) => {
      if (e.touches.length > 2) {
        timeout.current = setTimeout(toggleDebug, LONG_PRESS_TIMEOUT)
      }
    },
    [toggleDebug]
  )

  const touchEndHandler = useCallback(
    (e: TouchEvent) => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    },
    [timeout]
  )
  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'D') {
        toggleDebug()
      }
    },
    [toggleDebug]
  )
  useEffect(() => {
    document.addEventListener('touchstart', touchStartHandler)
    document.addEventListener('touchend', touchEndHandler)
    document.addEventListener('keydown', keyHandler)
    return () => {
      document.removeEventListener('touchstart', touchStartHandler)
      document.removeEventListener('touchend', touchEndHandler)
      document.removeEventListener('keydown', keyHandler)
    }
  }, [touchStartHandler, touchEndHandler, keyHandler])

  useEffect(() => {
    if (!process.env.REACT_APP_CLIENT_ID) {
      return
    }
    window.msal = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${context?.user?.tenant?.id}`,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'sessionStorage',
      },
    })
  }, [context?.user?.tenant?.id])

  if (!show) {
    return null
  }

  let debugText = `in teams: ${inTeams}`

  if (inTeams) {
    debugText += `\ncontext: ${JSON.stringify(context, null, 2)}`
    debugText += `\ntheme: ${JSON.stringify(theme, null, 2)}`
  }

  return (
    <div
      className={css`
        position: fixed;
        height: 200px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        padding: 3px;
        border-top: solid gray 2px;
        z-index: 99;
        background: #fff;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          padding: 2px;
        `}
      >
        <button onClick={window.unLinkMicrosoft}>
          Unlink Microsoft account
        </button>
        <button onClick={window.signOutWithFirebase}>
          Sign out of Firebase
        </button>
        <button onClick={window.signOutWithMicrosoft}>
          Sign out of Microsoft
        </button>
        <button
          onClick={async () => {
            await window.unLinkMicrosoft()
            await window.signOutWithMicrosoft()
            window.signOutWithFirebase()
          }}
        >
          Reset everything
        </button>
      </div>
      <textarea
        value={debugText}
        readOnly
        className={css`
          width: 100%;
          height: 100%;
        `}
      />
    </div>
  )
}

window.signinWithGoogle = () => {
  if (firebaseAuth.currentUser) {
    console.error('You must logout first')
  } else {
    signInWithPopup(firebaseAuth, new OAuthProvider('google.com'))
  }
}

window.signOutWithFirebase = signOutWithFirebase

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.microsoftTeams = microsoftTeams
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.teamsFx = teamsFx

window.firebaseAuth = firebaseAuth

window.unLinkMicrosoft = async () => {
  if (firebaseAuth.currentUser) {
    const result = await firebaseUnlink(
      firebaseAuth.currentUser,
      'microsoft.com'
    )
    console.log('unLinkMicrosoft', result)
    await reload(firebaseAuth.currentUser)
  } else {
    console.error('You must be logged in to unlink')
  }
}

window.signOutWithMicrosoft = () =>
  (window.msal as PublicClientApplication).logoutPopup()

declare global {
  interface Window {
    unLinkMicrosoft: () => Promise<void>
    signinWithGoogle: () => void
    signOutWithFirebase: () => void
    signOutWithMicrosoft: () => Promise<void>
    firebaseAuth: Auth
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    msal: PublicClientApplication
  }
}
