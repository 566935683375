import { useLocation } from 'react-router-dom'
import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Breadcrumb, ChevronEndMediumIcon } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'
import useNavigation from 'hooks/useNavigation'
import logo from 'assets/icons/logo.svg'
import { useContext } from 'react'
import { ChatContext, MilestoneDiscussion } from '@fable/chat'

const Nav = () => {
  const { urlMilestoneId, urlRoomName } = useNavigation()
  const { theme } = useTeams()
  const location = useLocation()
  const { colors } = useTheme()
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { milestoneDiscussionsQuery } = useContext(ChatContext)

  const milestone = milestoneDiscussionsQuery?.data?.data?.milestones?.find(
    (milestone: MilestoneDiscussion) =>
      milestone.milestone_id === urlMilestoneId
  )
  const milestoneName = milestone?.title
  const milestoneType = milestone?.type
  const lobbyName = milestoneDiscussionsQuery?.data?.data?.lobby?.title
  const hasRoom =
    !!milestoneName && milestoneType !== 'start' && milestoneType !== 'finish'

  return (
    <>
      <div
        className={css`
          height: 48px;
        `}
      />
      <nav
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          border-bottom: 1px solid ${theme.siteVariables.colors.grey[200]};
          min-height: 48px;
          width: 100%;
          z-index: 10;
          background: ${theme.siteVariables.colorScheme.grey.background};
          display: flex;
          align-items: center;
        `}
      >
        <FlexBox
          className={css`
            padding: 10px 20px;
            height: inherit;
            width: 100%;
            a,
            a:visited {
              text-decoration: none;
              color: ${theme.siteVariables.bodyColor};
            }
          `}
          alignItems="center"
          justifyContent="space-between"
        >
          <Breadcrumb aria-label="breadcrumb" size="medium">
            <Breadcrumb.Item active={location.pathname === '/club'}>
              <Breadcrumb.Link href={`/club${location.search}`}>
                <div
                  className={css`
                    background: ${colors.blackSwan};
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    margin-right: 6px;
                    > div {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 100%;
                    }
                    svg {
                      width: 90%;
                    }
                  `}
                >
                  <img width="20px" src={logo} alt="Fable Logo" />
                </div>
                Home
              </Breadcrumb.Link>
            </Breadcrumb.Item>
            {location.pathname.includes('discussions') ? (
              <>
                <Breadcrumb.Divider>
                  <ChevronEndMediumIcon
                    className={css`
                      svg {
                        fill: ${theme.siteVariables.bodyColor};
                      }
                    `}
                  />
                </Breadcrumb.Divider>
                <Breadcrumb.Item active>
                  <Breadcrumb.Link>
                    {milestoneName || lobbyName}
                    {hasRoom ? ` - ${urlRoomName}` : null}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
              </>
            ) : null}
          </Breadcrumb>
        </FlexBox>
      </nav>
    </>
  )
}

export default Nav
