import { useContext, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import pluralize from 'pluralize'
import { css, useTheme } from '@fable/theme'
import { BookImage, Button, FlexBox, Logo, Text } from '@fable/components'
import intervalToDuration from 'date-fns/intervalToDuration'
import { AttachmentPoll, PollChoice } from '../chatTypes'
import themes from './chatMessageAttachmentThemes'
import { ChatContext } from '../chat_context'
import { pollGet, pollSubmitAnswer } from '../lib/chatUtils'
import ChatMessageAttachmentPollResults from './ChatMessageAttachmentPollResults'

const ChatMessageAttachmentPoll = ({ poll }: { poll: AttachmentPoll }) => {
  const [showResults, setShowResults] = useState(false)
  const [showPoll, setShowPoll] = useState(false)
  const [choiceId, setChoiceId] = useState('')

  const { colors } = useTheme()
  const { defaultQueryOptions, clubId } = useContext(ChatContext)

  const submitAnswer = useMutation(
    'pollMutation',
    async () => pollSubmitAnswer({ clubId, pollId: poll.id, choiceId }),
    {
      onError: (error) => console.error(error),
      onSuccess: (data) => {
        if (data?.data?.user_has_voted && !showResults) {
          setShowResults(true)
          pollQuery.refetch()
        }
      },
    }
  )

  const pollQuery = useQuery(
    ['polls', poll.id],
    async () => pollGet({ clubId, pollId: poll.id }),
    {
      ...defaultQueryOptions,
      keepPreviousData: false,
      enabled: showPoll,
    }
  )

  const pollData =
    pollQuery?.data?.data?.data?.choices || pollQuery?.data?.data?.data?.books

  const button = useMemo(() => {
    if (poll.is_closed || poll.user_has_voted) {
      return {
        handleClick: () => setShowResults(true),
        text: 'View Results',
        disabled: false,
      }
    }

    if (showPoll) {
      return {
        handleClick: () => submitAnswer.mutate(),
        text: 'Vote',
        disabled: !choiceId,
      }
    }

    return {
      handleClick: () => setShowPoll(true),
      text: 'Vote',
      disabled: false,
    }
  }, [choiceId, poll.is_closed, poll.user_has_voted, showPoll, submitAnswer])

  const { theme } = poll

  if (pollQuery.isLoading || submitAnswer.isLoading) {
    return (
      <FlexBox
        centerAll
        className={css`
          width: 100%;
          height: 200px;
          border-radius: 8px;
          background: ${themes[theme].backgroundColor};
        `}
      >
        <Logo isIcon animate />
      </FlexBox>
    )
  }

  if (showResults) {
    return (
      <ChatMessageAttachmentPollResults
        poll={poll as AttachmentPoll}
        handleClickClose={() => setShowResults(false)}
      />
    )
  }

  return (
    <FlexBox
      data-testid="poll"
      flexDirection="column"
      className={css`
        position: relative;
        background: ${colors.fableGreen};
        padding: 20px;
        border-radius: 8px;
        span,
        h1 {
          color: ${colors.uiWhite} !important;
        }
      `}
    >
      <Text
        className={css`
          margin-top: 0;
          margin-bottom: 30px;
        `}
        type="header"
        sizing="S"
      >
        {poll.question}
      </Text>
      {showPoll && (
        <>
          <Text
            type="label"
            sizing="L"
            text={`Poll will automatically close in ${pluralize(
              'hour',
              intervalToDuration({
                start: new Date(),
                end: new Date(poll.expiration_at),
              }).hours,
              true
            )}`}
          />
          <form
            className={css`
              margin-top: 16px;
              height: inherit;
              overflow: auto;
              div + div {
                margin-top: 14px;
              }
            `}
            onSubmit={(e) => {
              e.preventDefault()
              submitAnswer.mutate()
            }}
          >
            {pollData?.map((choice: PollChoice) => (
              <FlexBox key={choice.id} gap="5px">
                <input
                  className={css`
                    all: revert !important;
                  `}
                  type="radio"
                  name="radio-button"
                  id={`radio-${choice.id}`}
                  checked={choiceId === choice.id}
                  onChange={() => setChoiceId(choice.id)}
                />
                {!!choice.cover_image && (
                  <BookImage
                    className={css`
                      width: 40px;
                    `}
                    image={choice.cover_image}
                  />
                )}
                <div>
                  <label htmlFor={`radio-${choice.id}`}>
                    {!!choice.title && (
                      <Text
                        type="label"
                        sizing="L"
                        className={css`
                          font-weight: bold;
                        `}
                        text={choice.title}
                      />
                    )}
                    <Text
                      type="label"
                      sizing="L"
                      text={choice.text || `by ${choice.author}`}
                    />
                  </label>
                </div>
              </FlexBox>
            ))}
          </form>
        </>
      )}
      <Button
        small
        width="fit-content"
        className={css`
          padding: 0 10px;
          margin-top: 16px;
          align-self: flex-end;
        `}
        onClick={button.handleClick}
        disabled={button.disabled}
      >
        {button.text}
      </Button>
    </FlexBox>
  )
}

export default ChatMessageAttachmentPoll
