import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme, css, QUOTE_THEME, cx } from '@fable/theme'
import { FlexBox, Logo, Text } from '@fable/components'
import { AttachmentQuote } from '../chatTypes'
import { ChatContext } from '../chat_context'

const ChatMessageAttachmentQuote = ({ quote }: { quote: AttachmentQuote }) => {
  const { clubBookQuery } = useContext(ChatContext)

  const { colors } = useTheme()
  const location = useLocation()

  const { theme, text } = quote
  const book = clubBookQuery?.data?.data?.book

  return (
    <div
      data-testid="quote"
      className={cx(
        css`
          background: ${colors[QUOTE_THEME[theme].backgroundColor]};
          padding: 20px;
          border-radius: 8px;
        `,
        'quote'
      )}
    >
      <Text
        className={css`
          height: 40px;
          margin: 0;
        `}
        type="header"
        sizing="L"
        color={colors[QUOTE_THEME[theme].quoteColor]}
      >
        &ldquo;
      </Text>
      <Text
        className={css`
          margin-top: 0;
          margin-bottom: 30px;
        `}
        type="header"
        sizing="S"
        color={colors.whiteFang}
      >
        {text}
      </Text>
      <FlexBox
        className={css`
          gap: 16px;
        `}
        justifyContent="space-between"
      >
        <div>
          <Text
            type="body"
            sizing="M"
            color={colors[QUOTE_THEME[theme].bookTitleColor]}
          >
            {book?.title}
          </Text>
          <Text
            type="body"
            sizing="M"
            color={colors[QUOTE_THEME[theme].authorColor]}
          >
            {book?.authors[0].name}
          </Text>
        </div>
        <a
          data-testid="bookCoverLink"
          /** @note when running e2e tests, if this links to staging, a JS alert will appear and cause the test to hang */
          href={
            location.search.includes('test')
              ? book?.url?.split('https://staging.fable.co')[1]
              : book?.url
          }
          target={location.search.includes('test') ? '' : '_blank'}
          rel="noreferrer"
        >
          <img
            className={css`
              width: 50px;
              border-radius: 4px;
            `}
            src={book?.cover_image_small}
            alt={`${book?.title} book cover`}
          />
        </a>
      </FlexBox>
      <Logo
        isLight
        className={css`
          svg {
            width: min(25%, 70px);
          }
        `}
      />
    </div>
  )
}

export default ChatMessageAttachmentQuote
