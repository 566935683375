import { User } from '@fable/types'
import ClubList from 'components/club/ClubList'
import { getClubs } from 'utils/query'

const EMPTY_STATE = `It looks like you are not in a club yet. Any book clubs you join will show up here.`

const YourClubs = ({ user }: { user: User }) => {
  return <ClubList user={user} emptyStateText={EMPTY_STATE} query={getClubs} />
}

export default YourClubs
