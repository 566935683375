const createElementForReplace = ({
  tag,
  content,
  attributes,
}: {
  tag: string
  content: any
  attributes: Record<string, any>
}) => {
  const element = document.createElement(tag)
  element.innerHTML = content

  for (const key in attributes) {
    element.setAttribute(key, attributes[key])
  }

  return element
}

export const replaceAtCaret = ({
  node,
  start,
  end,
  element,
  content,
  trailingSpace,
}: {
  /** @param {Node} node - The node containing the text to replace */
  node: Node
  /** @param {number} start - The start position in a focusNode */
  start: number
  /** @param {number} end - The end position in a focusNode */
  end: number
  /** @param {object} element - The element to replace with, must include tag and attributes array */
  element: {
    /** @param {string} tag - Tag for the element as used in document.createElement() */
    tag: string
    /** @param {object} attributes - Object of attributes to add to the element (key: value pair) */
    attributes: Record<string, any>
  }
  /** @param {any} content - What will be put inside the element */
  content?: any
  /** @param {boolean} trailingSpace - Ff true, a non-breaking space will be placed after the element. This helps to break out of the inserted element. */
  trailingSpace?: boolean
}) => {
  const selection = window.getSelection()
  const range = selection?.getRangeAt(0)

  if (range && selection && node) {
    selection.removeAllRanges()
    range.setStart(node, start)
    range.setEnd(node, end)

    const newElement = createElementForReplace({
      tag: element.tag,
      content: content || range.toString().trim(),
      attributes: element.attributes,
    })

    selection.addRange(range)
    // replace mention string with tag containing mention
    range.deleteContents()
    range.insertNode(newElement)
    range.collapse()
    // A zero width space allows backspacing up to the end of the inserted element without getting stuck inside of it
    const zeroWidthSpace = document.createTextNode('\u200B')
    range.insertNode(zeroWidthSpace)

    if (trailingSpace) {
      range.collapse()
      // Inserting a space will prevent getting stuck in the mention tag
      // https://stackoverflow.com/questions/14954316/contenteditable-move-cursor-outside-inserted-html-node-webkit-ie
      const nbSpace = document.createTextNode('\u00A0')
      range.insertNode(nbSpace)
    }

    selection?.addRange(range)
    range.collapse()
  }
}

export const charCodeIsSpace = (
  /** @param {number | undefined | null} code - You can get this via String.charCodeAt() */
  /** @note Codes 32 (whitespace), 160 (non-breaking space), and 8203 (zero-width space) are all acceptable spaces */
  code: number | undefined | null
) => code === 32 || code === 160 || code === 8203

export const replaceOuterElement = ({
  node,
  textContent,
  retainCaretPosition,
}: {
  node: Node
  textContent: string
  retainCaretPosition?: boolean
}) => {
  const textNode = document.createTextNode(textContent)
  ;(node as Element).replaceWith(textNode)

  if (retainCaretPosition) {
    const selection = window.getSelection()
    const range = selection?.getRangeAt(0)

    if (selection && range && textContent) {
      range.setStart(textNode, textContent.length)
      selection.removeAllRanges()
      selection.addRange(range)
      range.collapse()
    }
  }
}

export const recursivelyParseNodes = ({
  /** @param {Node} node */
  node,
  /** @param {[key in keyof Partial<HTMLElementTagNameMap>]: ((node: Node) => void)[]} callbacks -
   * Callbacks to run for each nodeName. Ex: { span: [(node: Node) => {...}], br: [(node:Node) => {...}] } */
  callbacks,
}: {
  node: Node
  callbacks: {
    [key in keyof Partial<HTMLElementTagNameMap>]: ((node: Node) => void)[]
  }
}) => {
  const run = (childNodes?: NodeListOf<ChildNode>) => {
    if (!childNodes?.length) return

    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i]

      for (const key in callbacks) {
        if (node.nodeName === key.toUpperCase()) {
          callbacks[key as keyof Partial<HTMLElementTagNameMap>]?.forEach(
            (fn) => {
              fn(node)
            }
          )
        } else {
          run(node.childNodes)
        }
      }
    }
  }

  run(node.childNodes)
}

export const getBoundingClientRectForChar = (char: string) => {
  const selection = window.getSelection()
  const range = selection?.getRangeAt(0).cloneRange()
  if (range && selection?.focusNode?.textContent) {
    const indexOfChar = selection.focusNode.textContent.lastIndexOf(char) || 0

    range.setStart(selection.focusNode, Math.max(indexOfChar, 0))

    // Adding zeroWidthSpace allows the rect to work when there's no content
    // https://stackoverflow.com/a/59780954/7569308
    const zeroWidthSpace = document.createTextNode('\u200B')
    range?.insertNode(zeroWidthSpace)
    const rect = range?.getBoundingClientRect()
    zeroWidthSpace.remove()

    return rect
  }
}
