import { css, useTheme } from '@fable/theme'
import { Provider } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'
import merge from 'lodash/merge'
import { ReactNode } from 'react'
import { TeamsThemeContext } from 'utils/TeamsThemeContex'

const AppWrapper = ({ children }: { children: ReactNode }) => {
  const { colors } = useTheme()
  const { context: teamsContext, theme, themeString } = useTeams()

  const customTheme = merge(theme, {
    siteVariables: merge(theme.siteVariables, {
      noHover: {
        ':hover': {
          backgroundColor: 'no',
          boxShadow: 'no',
          borderColor: 'no',
        },
      },
      colorScheme: merge(theme.siteVariables.colorScheme, {
        brand: {
          ...theme.siteVariables.colorScheme.brand,
          borderFocus1: colors.fableGreen,
        },
      }),
      colors: merge(theme.siteVariables.colors, {
        // 210 and 435 do not exist but the design requires these colors
        grey: {
          ...theme.siteVariables.colors.grey,
          210: '#eeeeee',
          435: '#686868',
        },
      }),
    }),
  })

  const { grey } = customTheme.siteVariables.colors

  return (
    <TeamsThemeContext.Provider value={{ theme: customTheme, themeString }}>
      <Provider
        className={css`
          input.ui-box,
          textarea.ui-textarea {
            &::placeholder {
              color: ${grey[400]};
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: ${grey[400]};
            }
            &::-ms-input-placeholder {
              color: ${grey[400]};
            }
          }
          // Temporarily copying styles from other MS components since there's no
          // time picker component. There might be one in an updated library
          // When/if updating the library, swap this out for theirs if there is one
          input[type='time'] {
            width: 100%;
            background: rgb(243, 242, 241);
            border-color: transparent;
            color: rgb(37, 36, 35);
            border-radius: 4px;
            padding: 0.3125rem 0.75rem;
            height: 32px;
            cursor: pointer;
            &:focus {
              outline: none;
              border-bottom-color: ${colors.fableGreen};
            }
          }
          .ui-datepicker {
            position: relative;
            width: 100%;
            .ui-box {
              width: 100%;
              input {
                width: 100%;
              }
            }
            button {
              position: absolute;
              right: 0;
              box-shadow: none;
              outline: none;
              background: transparent;
              border: none;
              top: 0;
              z-index: 1;
            }
          }
        `}
        theme={customTheme}
        styles={{
          height: '100%',
          backgroundColor:
            teamsContext?.page.frameContext === 'task' ? 'white' : grey[210],
        }}
      >
        {children}
      </Provider>
    </TeamsThemeContext.Provider>
  )
}

export default AppWrapper
