import { Link } from 'react-router-dom'
import { css, cx, useTheme, FableTheme } from '@fable/theme'
import FlexBox from '../FlexBox'
import { OptionsMenuItem, OptionsMenuSecondaryAction } from '../types'

interface Props {
  items: OptionsMenuItem[]
  className?: string
  itemClass?: string
  secondaryAction?: OptionsMenuSecondaryAction
}

export const OptionsMenuItems: React.VFC<Props> = ({
  className,
  items,
  secondaryAction,
}) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const renderItems = items.map((item: OptionsMenuItem, i: number) => {
    const { action, path, title, icon, color } = item

    if (action) {
      return (
        <div
          key={i}
          className={cx(
            styles.item,
            !!icon && styles.itemIcon,
            color && styles.itemColor(color),
            'fds-ui--dropdown-item'
          )}
          onClick={(e) => {
            e.stopPropagation()
            action()
          }}
        >
          {icon && <span className={styles.icon}>{item.icon}</span>}
          {title}
        </div>
      )
    }

    return (
      path && (
        <Link
          key={i}
          className={cx(
            styles.item,
            !!icon && styles.itemIcon,
            color && styles.itemColor(color),
            'fds-ui--dropdown-item no-underline'
          )}
          to={path}
        >
          {icon && <span className={styles.icon}>{item.icon}</span>}
          {title}
        </Link>
      )
    )
  })

  const renderSecondaryAction = secondaryAction && (
    <div className={className}>
      <div
        className={cx(
          styles.item,
          css`
            color: ${secondaryAction.color};
          `,
          'fds-ui--dropdown-item fds-ui--dropdown-item-secondary'
        )}
        onClick={secondaryAction.action}
      >
        {secondaryAction.title}
      </div>
    </div>
  )

  return (
    <FlexBox flexDirection="column" flexWrap="wrap" className={className}>
      {renderItems}
      {items.length > 0 && secondaryAction && (
        <span className={styles.divider} />
      )}
      {renderSecondaryAction}
    </FlexBox>
  )
}

const getStyles = ({ colors, motion, fonts }: FableTheme) => ({
  item: css`
    padding: 17px 24px;
    cursor: pointer;
    color: ${colors.blackSwan};
    font-family: ${fonts.body};
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    ${motion.transition('background-color')};

    &:hover {
      background-color: ${colors.almostWhite};
    }
  `,
  itemIcon: css`
    font-size: 14px;
    letter-spacing: -0.3px;
    font-weight: normal;
    display: flex;
    align-items: center;
  `,
  itemColor: (color: string) => css`
    color: ${color};

    svg path {
      fill: ${color};
    }
  `,
  icon: css`
    display: flex;
    margin-right: 13px;
    width: 22px;
    flex-shrink: 0;
  `,
  divider: css`
    margin: 8px 0;
    height: 1px;
    background-color: ${colors.whiteSmoke};
  `,
})
