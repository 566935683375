import {
  cloneElement,
  isValidElement,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ContentEditable from 'react-contenteditable'
import { css, cx, useTheme } from '@fable/theme'
import { v4 as uuidv4 } from 'uuid'
import ChatInputMentionPicker from './ChatInputMentionPicker'
import {
  charCodeIsSpace,
  replaceOuterElement,
  recursivelyParseNodes,
  getBoundingClientRectForChar,
  filterUsersByKeyword,
  formatMessageTextToSend,
  insertMentionLink,
  MentionLinkArgs,
  createMentionsArrayFromString,
} from '../lib'
import { SendMessageMutation } from '../chatTypes'
import { ChatContext } from '../chat_context'
import { Button, CloseIcon, FlexBox } from '@fable/components'

const ChatInput = ({
  dimensions,
  actionBarComponent,
  className,
}: {
  className?: string
  dimensions: {
    minHeight: number
    maxHeight?: number
    paddingTop: number
    paddingLeft: number
  }
  /** @param {React.ReactElement<{ className?: any; handleSendMessage?: () => void; disableSend?: boolean }>} actionBarComponent - The action bar which should at least contain a send button. ChatInput will handle sending the message. */
  actionBarComponent: React.ReactElement<{
    className?: any
    handleSendMessage?: () => void
    disableSend?: boolean
  }>
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { colors, mediaQueries } = useTheme()
  const popupCoords = useRef({ left: 0, bottom: 0 })
  const chatInput = useRef<HTMLDivElement | null>(null)
  const lastKey = useRef('')
  const mention = useRef('')

  const { clubMembersQuery, sendMessage, image, setImage } =
    useContext(ChatContext)

  const clubMembers = clubMembersQuery?.data?.data?.results || []

  const [isMentioning, setIsMentioning] = useState(false)
  const [content, setContent] = useState<any>('')
  const [canSend, setCanSend] = useState(false)

  const handleSubmit = () => {
    const message = formatMessageTextToSend(content)
    if (!image?.fileData && !message) return

    const mentions = createMentionsArrayFromString({
      message,
      clubMembers,
    })
    const args: SendMessageMutation = {
      id: uuidv4(),
      message,
      mentions,
      hasSpecialMention: message.includes('@club'),
      image,
    }

    sendMessage(args)
    // Clear input after sending
    setTimeout(() => {
      setContent('')
      chatInput.current?.focus()
    }, 250)
  }

  const clubMembersFiltered = () =>
    filterUsersByKeyword({
      clubMembers,
      keyword: mention.current.replace('@', ''),
    })

  const resetMentioning = () => {
    mention.current = ''
    setIsMentioning(false)
  }

  const initInsertMention = useCallback((userInfo: MentionLinkArgs) => {
    const selection = window.getSelection()
    if (!selection?.focusNode) return

    const parentElement = selection.focusNode.parentElement
    const textContent = selection.focusNode.textContent

    if (parentElement?.nodeName === 'SPAN' && textContent) {
      // If you're within an span, replace the span with the plaintext mention before inserting the new one
      replaceOuterElement({
        node: parentElement,
        textContent,
        retainCaretPosition: true,
      })
    }

    insertMentionLink({ userInfo })
    resetMentioning()
    setContent(chatInput.current?.innerHTML.trim())
  }, [])

  const initEditMention = (userName: string) => {
    mention.current = userName
    setIsMentioning(true)
  }

  const setSelectedUser = (userInfo: MentionLinkArgs) => {
    mention.current = userInfo.display_name
    initInsertMention(userInfo)
  }

  const handleBackspace = ({ isEditing }: { isEditing: boolean }) => {
    const selection = window.getSelection()
    const textContent = selection?.focusNode?.textContent
    if (!selection?.anchorNode) return
    if (textContent === null || textContent === undefined) return

    if (isEditing) {
      // If editing, the entire mention.current needs to be updated, not appended
      // This is easy since the focusNode is the programmatically inserted span element the mention is inside of
      mention.current = textContent
    } else if (mention.current?.length > 0) {
      mention.current = mention.current.slice(0, -1)
    }
  }

  const handleComposeMention = (e: any) => {
    const selection = window.getSelection()
    const textContent = selection?.focusNode?.textContent

    if (!isMentioning) return
    if (textContent === null || textContent === undefined) return
    // Don't append 'Enter', 'Backspace' etc. to mention string (e.nativeEvent.data will be blank if it's not a character)
    // @ is already logged in mention.current at initiation
    if (!e.nativeEvent.data || e.nativeEvent.data === '@') return

    mention.current += e.nativeEvent.data
  }

  const handleEditMention = () => {
    const selection = window.getSelection()
    const textContent = selection?.focusNode?.textContent

    if (textContent === null || textContent === undefined) return
    if (!selection?.focusNode) return

    if (!isMentioning) {
      initEditMention(textContent)
    }

    const range = selection.getRangeAt(0)
    range.setStart(selection.focusNode, 0)
    range.setEnd(selection.focusNode, selection.focusOffset)
    const rangeStr = range.toString()

    if (rangeStr === '@') {
      // When backspacing up to an @, restart mentioning from that point
      const parentElement = selection.focusNode.parentElement

      replaceOuterElement({
        node: parentElement as Node,
        textContent: rangeStr,
        retainCaretPosition: true,
      })
      setContent(chatInput.current?.innerHTML.trim())
      range.collapse()
      resetMentioning()
      mention.current = rangeStr
      setIsMentioning(true)
    } else {
      range.collapse()
      mention.current = textContent
    }
  }

  const handleChange = (e: any) => {
    const selection = window.getSelection()
    if (!selection?.focusNode) return

    const parentElement = selection.focusNode.parentElement
    const isSpan = parentElement?.nodeName === 'SPAN'

    // Because the onKeyDown method does not read state, this weird method must be used to send a message on Enter
    if (canSend) {
      handleSubmit()
      setCanSend(false)

      return
    }

    setContent(e.target.value)

    if (isSpan) {
      handleEditMention()
    } else if (isMentioning && !isSpan) {
      handleComposeMention(e)
    }

    // chatInput.current's childNodes do not contain the most up to date textContent onKeyDown
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      recursivelyParseNodes({
        node: chatInput.current as Node,
        callbacks: {
          span: [
            (node: Node) => {
              if (
                !node.textContent ||
                (node.textContent && !node.textContent.includes('@'))
              ) {
                if (!node.textContent) {
                  ;(node as Element).remove()
                } else {
                  replaceOuterElement({
                    node,
                    textContent: node.textContent,
                  })
                }

                setContent(chatInput.current?.innerHTML.trim())
                resetMentioning()
              }
            },
          ],
        },
      })
    }
  }

  const calculateMemberListCoords = () => {
    const charRect = getBoundingClientRectForChar('@')

    if (charRect) {
      const chatInputRect = chatInput.current?.getBoundingClientRect() || {
        left: 0,
        bottom: 0,
        height: 0,
      }
      const caretRectTop = Math.abs(
        charRect.top - chatInputRect.bottom + dimensions.paddingTop
      )
      const bottom = caretRectTop + dimensions.paddingTop * 2

      popupCoords.current = {
        left: Math.max(
          charRect.left - chatInputRect.left,
          dimensions.paddingLeft
        ),
        bottom,
      }
    }
  }

  /**
   * @note
   * This function initiates mentioning on @
   * Inserts mention if there's a match when hitting enter
   * Calls handleBackspace to handle backspacing
   * Resets a mention when adding a space after the @
   * Sets lastKey
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const selection = window.getSelection()
    if (!selection?.focusNode) return

    const textContent = selection.focusNode.textContent
    // There must be a space or no previous key otherwise it could be an email address
    const lastCharCode = textContent?.charCodeAt(textContent?.length - 1)
    const match = clubMembersFiltered()[0] || {
      displayName: mention.current.replace('@', ''),
      id: mention.current === '@club' ? 'channel' : '',
    }
    const shouldStartMentioning =
      e.key === '@' &&
      (charCodeIsSpace(lastCharCode) || Number.isNaN(lastCharCode))
    const shouldInsertRealMention =
      e.key === 'Enter' && mention.current.replace('@', '').length > 0
    // Dummy mentions can be edited any time to turn them into real mentions
    // They don't contain a data-uuid attribute and are not styled
    const shouldInsertDummyMention =
      e.key === 'Enter' && mention.current.length > 0

    if (shouldStartMentioning) {
      if (mention.current.includes('@')) {
        resetMentioning()
      }

      mention.current = '@'
      setIsMentioning(true)
    } else if (shouldInsertRealMention) {
      e.preventDefault()
      initInsertMention(match as MentionLinkArgs)
    } else if (shouldInsertDummyMention) {
      initInsertMention({ display_name: mention.current.replace('@', '') })
    } else if (e.key === 'Backspace') {
      const parentElement = selection.focusNode.parentElement
      const isSpan = parentElement?.nodeName === 'SPAN'

      handleBackspace({ isEditing: isSpan })
    }

    // Log the last keypress to react to deleting all content and exiting a mention without making a selection
    lastKey.current = e.key

    // Submit on enter, default new line behavior when holding shift
    if (e.key === 'Enter' && !e.shiftKey) {
      /**
       * State is out of sync from this method
       * If handleSubmit is called from here, content will be blank
       */
      setCanSend(true)
    }
  }

  useEffect(() => {
    if (mention.current && mention.current.split(' ').length > 2) {
      // Insert dummy mention when there are more than one spaces in a mention (essentially leave an editable unstyled mention with no id)
      initInsertMention({ display_name: mention.current.replace('@', '') })
    }

    if (!mention.current || (!content && lastKey.current !== '@')) {
      // This occurs when deleting all content of editor and then entering @
      resetMentioning()
    }
  }, [content, initInsertMention])

  // Running this on every re-render ensures the popup is always above the @ symbol, even when the line wraps while mentioning
  if (isMentioning) {
    calculateMemberListCoords()
  }

  if (!isValidElement(actionBarComponent)) {
    console.error('Component is invalid:\n', actionBarComponent)

    return null
  }

  return (
    <div
      className={cx(
        'chat-input',
        css`
          width: 100%;
          background: ${colors.whiteFang};
        `,
        className
      )}
    >
      <div
        className={cx(
          'chat-input-editable-wrapper',
          css`
            position: relative;
            width: 100%;
            min-height: ${dimensions.minHeight}px;
            * {
              font-size: 16px;
            }
          `
        )}
      >
        {image?.render && (
          <FlexBox
            justifyContent="flex-end"
            className={css`
              margin: 16px 0px 0px 16px;
              width: 80px;
              height: 80px;
              border-radius: 8px;
              overflow: hidden;
              border: 2px solid ${colors.pewterGrey};
              background: url(${image.render}) center / cover no-repeat;
            `}
          >
            <Button
              negative
              onClick={() => setImage(null)}
              className={css`
                margin: 2px 2px 0px 0px;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.4);
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
                border-radius: 100%;
                transition: 0.2s;
                cursor: pointer;
                background: white;
                &:hover {
                  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                  transform: scale(1.05);
                }
              `}
            >
              <CloseIcon />
            </Button>
          </FlexBox>
        )}
        <ContentEditable
          innerRef={chatInput}
          html={content}
          onKeyDown={handleKeyDown}
          className={cx(
            'chat-input-editable-content',
            css`
              overflow-y: auto;
              width: 100%;
              padding: ${dimensions.paddingTop}px ${dimensions.paddingLeft}px;
              span:not([data-uuid='000']) {
                font-weight: bold;
                color: ${colors.blue};
                background: ${colors.mayaBlueLight};
              }
            `,
            dimensions.maxHeight
              ? css`
                  max-height: ${dimensions.maxHeight}px;
                `
              : ''
          )}
          onChange={handleChange}
        />
        {isMentioning && (
          <div
            className={cx(
              'chat-input-mention-picker-wrapper',
              css`
                position: absolute;
                z-index: 1;
                left: 0;
                bottom: ${window.innerHeight -
                36 -
                (chatInput.current?.getBoundingClientRect().top || 0)}px;
                width: 100%;
                ul {
                  width: 100%;
                }
                ${mediaQueries.tablet} {
                  width: fit-content;
                  ul {
                    width: fit-content;
                  }
                  left: ${popupCoords.current.left}px;
                  bottom: ${popupCoords.current.bottom}px;
                }
              `
            )}
          >
            <ChatInputMentionPicker
              onClickUser={setSelectedUser}
              clubMembers={clubMembersFiltered() as MentionLinkArgs[]}
            />
          </div>
        )}
      </div>
      {cloneElement(actionBarComponent, {
        className: cx(
          css`
            width: 100%;
            padding: 0 ${dimensions.paddingLeft}px;
          `,
          actionBarComponent.props.className
        ),
        handleSendMessage: handleSubmit,
        disableSend: !content && !image?.fileData,
      })}
    </div>
  )
}

export default ChatInput
