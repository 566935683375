import { useContext, useState } from 'react'
import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { ChatContext } from '@fable/chat'
import { get } from '@fable/api'
import {
  Button,
  OpenOutsideIcon,
  Skeleton,
  Text,
} from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'
import invokeTask from 'utils/invokeTask'
import { ClubContext } from 'utils/ClubContext'
import addCircle from 'assets/icons/add-circle-no-border.svg'
import ChannelTabBookModal from './ChannelTabBookModal'

const ChannelTabBannerControlsBook = () => {
  const [showModal, setShowModal] = useState(false)
  const { mediaQueries } = useTheme()
  const { theme, context } = useTeams()
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clubDetailsQuery, user, clubBookQuery } = useContext(ChatContext)
  const { refetchAll, setShowChat } = useContext(ClubContext)

  const { grey } = theme.siteVariables.colors
  const club = clubDetailsQuery?.data?.data
  const isModerator = club?.creator.id === user?.id
  const book = clubBookQuery?.data?.data?.book

  const onBookClick = async () => {
    if (
      context?.app.host.clientType === 'ios' ||
      context?.app.host.clientType === 'android'
    ) {
      const result = await get(`/deep_link?type=book&id=${book?.isbn}`)
      window.open(result.data.link, '_blank')
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      {showModal && club?.current_book && (
        <ChannelTabBookModal
          closeModal={() => setShowModal(false)}
          book={club.current_book}
        />
      )}
      {club?.current_book ? (
        <FlexBox alignItems="center">
          <img
            className={css`
              margin-right: 14px;
              width: 74px;
            `}
            src={`${club.current_book?.cover_image_resize}?w=148`}
            alt={`Book cover for ${club.current_book?.title}`}
          />
          <div>
            <Text
              color={theme.siteVariables.bodyColor}
              className={css`
                &.ui-text {
                  white-space: nowrap;
                }
              `}
              size="small"
            >
              Currently Reading
            </Text>
            <br />
            <Text
              color={theme.siteVariables.bodyColor}
              className={css`
                &.ui-text {
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 18px;
                  ${mediaQueries.tabletL} {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              `}
            >
              {club.current_book?.title}
            </Text>
            <br />
            <FlexBox
              className={css`
                margin-top: 6px;
                gap: 6px;
              `}
              alignItems="center"
              flexWrap="wrap"
            >
              <Button
                className={css`
                  width: 100px;
                `}
                onClick={onBookClick}
                icon={<OpenOutsideIcon />}
                iconPosition="after"
                content="View"
              />
              {isModerator && (
                <Button
                  className={css`
                    width: 100px;
                  `}
                  onClick={() =>
                    invokeTask({
                      title: 'Choose your book',
                      slug: 'add-book',
                      onSuccess: () => {
                        setShowChat({ show: false, callback: refetchAll })
                      },
                    })
                  }
                >
                  Change
                </Button>
              )}
              {/* Hide until ready */}
              {/* <Button
                  onClick={() => window.alert('Requires linking to mobile app')}
                >
                  Sample
                </Button> */}
            </FlexBox>
            {book?.fable_prompts_document && (
              <Button
                className={css`
                  margin-top: 6px;
                  width: 100%;
                `}
                onClick={() =>
                  window.open(book.fable_prompts_document || '', '_blank')
                }
                icon={<OpenOutsideIcon />}
                iconPosition="after"
                content="Discussion Prompts"
              />
            )}
          </div>
        </FlexBox>
      ) : (
        <Skeleton>
          <FlexBox gap="14px">
            <Skeleton.Shape
              className={css`
                flex: 0 0 auto;
              `}
              width="48px"
              height="65px"
            >
              {isModerator && (
                <FlexBox
                  centerAll
                  className={css`
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <Button
                    onClick={() =>
                      invokeTask({
                        title: 'Choose your book',
                        slug: 'add-book',
                        onSuccess: refetchAll,
                      })
                    }
                    iconOnly
                    inverted
                    flat
                    circular
                    styles={{ border: 'none' }}
                    aria-label="Add a book"
                    icon={
                      <img
                        src={addCircle}
                        alt="Plus sign in a circle. Click to add a book."
                      />
                    }
                  />
                </FlexBox>
              )}
            </Skeleton.Shape>
            {isModerator ? (
              <FlexBox
                justifyContent="center"
                flexDirection="column"
                className={css`
                  margin-left: 14px;
                `}
              >
                <Text
                  styles={{
                    marginBottom: '6px',
                    display: 'block',
                  }}
                  weight="bold"
                  size="small"
                  color={grey[450]}
                >
                  Add a book to create your Milestones
                </Text>
                <Text
                  styles={{
                    display: 'block',
                  }}
                  size="smaller"
                  color={grey[450]}
                >
                  You'll automatically see Start, Finish, and optional
                  chapter-based milestones.
                </Text>
              </FlexBox>
            ) : (
              <div
                className={css`
                  flex: 1 0 auto;
                `}
              >
                <Skeleton.Shape width="60%" height="16px" />
                <Skeleton.Shape width="40%" height="16px" />
                <Skeleton.Shape width="50%" height="16px" />
              </div>
            )}
          </FlexBox>
        </Skeleton>
      )}
    </>
  )
}

export default ChannelTabBannerControlsBook
