import { useContext } from 'react'
import { useQuery } from 'react-query'
import { Button, CloseIcon, FlexBox, Text } from '@fable/components'
import { useTheme, css } from '@fable/theme'
import { AttachmentPoll, PollResult } from '../chatTypes'
import { ChatContext } from '../chat_context'
import { pollGet } from '../lib'
import pluralize from 'pluralize'
import intervalToDuration from 'date-fns/intervalToDuration'

const ChatMessageAttachmentPollResults = ({
  poll,
  handleClickClose,
}: {
  poll: AttachmentPoll
  handleClickClose?: () => void
}) => {
  const { colors } = useTheme()
  const { defaultQueryOptions, clubId } = useContext(ChatContext)

  const pollQuery = useQuery(
    ['polls', poll.id],
    async () => pollGet({ clubId, pollId: poll.id }),
    {
      ...defaultQueryOptions,
      enabled: true,
    }
  )

  const renderHeaderText = () => {
    if (poll.is_closed || !poll.user_has_voted) return poll.question

    if (poll.user_has_voted) return 'Thanks for voting!'

    return ''
  }

  const renderBar = (result: PollResult) => (
    <FlexBox
      alignItems="center"
      className={css`
        position: relative;
        padding-left: 16px;
        width: 100%;
        height: 41px;
        border-radius: 90px;
        overflow: hidden;
        background: ${colors.pinkMoonLight};
        border: 2px solid ${result.selected ? colors.pinkMoon : 'transparent'};
      `}
    >
      <div
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: ${result.percentage}%;
          border-radius: 90px;
          background: ${colors.pinkMoon};
          z-index: 0;
        `}
      />
      <Text
        className={css`
          z-index: 1;
          white-space: nowrap;
        `}
        type="body"
        sizing="M"
        color={colors.graphite}
        text={result.text}
        title={result.text}
      />
    </FlexBox>
  )

  return (
    <div
      className={css`
        position: relative;
        background: ${colors.whiteFang};
        padding: 20px;
        border-radius: 8px;
        div + div {
          margin-top: 12px;
        }
      `}
    >
      {!!handleClickClose && (
        <Button
          className={css`
            position: absolute;
            right: 10px;
            top: 10px;
          `}
          onClick={handleClickClose}
          transparent
          width="20px"
          height="20px"
        >
          <CloseIcon
            className={css`
              width: 100%;
              height: 100%;
            `}
          />
        </Button>
      )}
      <div
        className={css`
          margin: 0 0 20px 0;
          padding: 0;
          text-align: center;
        `}
      >
        <Text sizing="L" type="subhead" text={renderHeaderText()} />
        {!poll.is_closed && (
          <Text
            type="label"
            sizing="M"
            color={colors.graphiteLight}
            text={`Poll will automatically expire in ${pluralize(
              'hour',
              intervalToDuration({
                start: new Date(),
                end: new Date(poll.expiration_at),
              }).hours,
              true
            )}`}
          />
        )}
      </div>
      {pollQuery?.data?.data?.data?.results?.map((result: PollResult) => (
        <FlexBox key={result.id} alignItems="center">
          {renderBar(result)}
          <Text
            className={css`
              display: block;
              width: 50px;
              text-align: right;
            `}
            type="body"
            sizing="M"
            color={colors.graphite}
            text={`${result.percentage}%`}
          />
        </FlexBox>
      ))}
    </div>
  )
}

export default ChatMessageAttachmentPollResults
