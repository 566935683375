import { Club, User } from '@fable/types'
import { css, useTheme } from '@fable/theme'
import { useQuery } from 'react-query'
import { UserFriendsIcon } from '@fluentui/react-icons-northstar'
import { Text, Card, Avatar, OpenOutsideIcon } from '@fluentui/react-northstar'
import { FlexBox, Grid } from '@fable/components'
import { cloudImageResize } from '@fable/utils'
import { useTeams } from 'hooks/useTeams'
import { defaultQueryOptions } from '../../utils/query'
import peopleAtWorkImage from '../../assets/graphics/people-at-work.png'
import { executeTeamsDeepLink } from '../../utils/teamsDeepLink'
import { FableApiResponse } from '@fable/api'

const EmptyState = ({ text }: { text: string }) => {
  const { theme } = useTeams()
  const { mediaQueries } = useTheme()
  return (
    <div
      className={css`
        max-width: 1087px;
        padding: 12px;
        text-align: center;
        ${mediaQueries.tabletL} {
          margin: 0 auto 20px auto;
          padding: 12px 26px;
        }
      `}
    >
      <Text
        color={theme.siteVariables.bodyColor}
        weight="bold"
        size="larger"
        content="Welcome to Fable"
        align="center"
      />
      <img src={peopleAtWorkImage} alt="people at work" />
      <Text color={theme.siteVariables.bodyColor} align="center" as="p">
        {text}
      </Text>
      <Text color={theme.siteVariables.bodyColor} align="center" as="p">
        Want to start a book club for your team? Simply add the Fable tab to any
        team channel, and follow the instructions from there.
      </Text>
      <Text color={theme.siteVariables.bodyColor} align="center" as="p">
        Happy reading!
      </Text>
    </div>
  )
}

const ClubsList = ({
  user,
  emptyStateText,
  query,
}: {
  user: User
  emptyStateText: string
  query: (userId: string) => Promise<FableApiResponse>
}) => {
  const { theme } = useTeams()
  const { mediaQueries } = useTheme()

  const clubListQuery = useQuery(
    'genres',
    async () => await query(user?.id || ''),
    {
      ...defaultQueryOptions,
      enabled: !!user?.id,
    }
  )

  const clubList = clubListQuery.data?.data || []
  const { grey } = theme.siteVariables.colors
  const clubImageSource = (club: Club) =>
    club.hero_image ||
    club.creator_illustration ||
    club.logo_image ||
    club.creator?.pic

  const navigateToClubTab = (
    e: React.MouseEvent,
    club: Partial<Club & { url: string; id: string }>
  ) => {
    e.preventDefault()
    executeTeamsDeepLink({
      appId: process.env.REACT_APP_TEAMS_APP_ID!,
      pageId: `club-${club.id}`,
      channelId: club.tenant_channel_id,
    })
  }

  return clubList.length > 0 ? (
    <div
      className={css`
        max-width: 1087px;
        padding: 12px;
        ${mediaQueries.tabletL} {
          margin: 0 auto 20px auto;
          padding: 12px 26px;
        }
      `}
    >
      <Text
        color={theme.siteVariables.bodyColor}
        weight="bold"
        size="larger"
        content="Your Clubs"
      />
      <Grid
        gridTemplateColumns={`repeat(auto-fit, minmax(max(50px, 197px), 1fr))`}
        className={css`
          gap: 15px;
          margin-top: 21px;
          a {
            text-decoration: none;
          }
        `}
      >
        {clubList.map((club: Partial<Club & { url: string; id: string }>) => (
          <button
            key={club.id}
            onClick={(e: React.MouseEvent) => navigateToClubTab(e, club)}
          >
            <Card
              fluid
              elevated
              size="large"
              styles={{
                ...theme.siteVariables.noHover,
                height: '100%',
                padding: '8px 9px',
              }}
            >
              <FlexBox
                className={css`
                  height: 100%;
                `}
              >
                <FlexBox
                  centerAll
                  className={css`
                    flex: 1 1 auto;
                    background: ${club.theme_color};
                    padding: 11px 9px;
                    border-radius: 5px;
                    width: 97px;
                    height: 130px;
                  `}
                >
                  {clubImageSource(club as Club) ? (
                    <div
                      className={css`
                        flex: 1 1 auto;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12))
                          drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
                        border-radius: 3px;
                        background: url(${cloudImageResize(
                            clubImageSource(club as Club) || '',
                            300
                          )})
                          top center / cover no-repeat;
                      `}
                    />
                  ) : (
                    <Text size="larger" color="white" weight="bold">
                      {club.title
                        ?.split(' ')
                        .map((x: string) => x.substring(0, 1).toUpperCase())}
                    </Text>
                  )}
                </FlexBox>
                <FlexBox
                  flexDirection="column"
                  justifyContent="space-between"
                  className={css`
                    margin-left: 1em;
                    width: 50%;
                    height: 100%;
                  `}
                >
                  <div>
                    <Text
                      color={grey[500]}
                      size="small"
                      content={`${club.creator?.display_name} moderates`}
                    />
                    <br />
                    <Text
                      color={theme.siteVariables.bodyColor}
                      weight="bold"
                      size="medium"
                      content={club.title}
                    />
                  </div>
                  <div
                    className={css`
                      color: ${grey[450]};
                      font-size: 0.85rem;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    `}
                  >
                    <Text
                      className={css`
                        margin-right: 0.25em;
                      `}
                      color={theme.siteVariables.bodyColor}
                      size="small"
                      weight="semibold"
                      content={club.member_count}
                    />
                    <UserFriendsIcon size="small" />
                  </div>
                </FlexBox>
                <div
                  className={css`
                    flex: 1;
                    position: relative;
                  `}
                >
                  <Avatar image={club?.creator?.pic} />
                  <div
                    className={css`
                      position: absolute;
                      bottom: 5px;
                      right: 5px;
                      * {
                        color: ${theme.siteVariables.bodyColor};
                      }
                    `}
                  >
                    <OpenOutsideIcon />
                  </div>
                </div>
              </FlexBox>
            </Card>
          </button>
        ))}
      </Grid>
    </div>
  ) : (
    <EmptyState text={emptyStateText} />
  )
}

export default ClubsList
