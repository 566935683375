import { useEffect, useRef } from 'react'
import Ably from 'ably'
import { User } from '@fable/types'

const useAbly = ({
  ready,
  pushChannel,
  onMessage,
  user,
}: {
  ready: boolean
  pushChannel: string
  onMessage: (message: Partial<any>) => void
  user: User
}) => {
  const initialized = useRef(false)
  const ablyChannel = useRef<Ably.Types.RealtimeChannelPromise | null>(null)

  // Pause receiving messages from Ably while mutating messages query cache
  const attachAbly = () => {
    if (ablyChannel.current?.state === 'detached') {
      ablyChannel.current?.attach()
    }
  }
  const detachAbly = () => {
    if (ablyChannel.current?.state === 'attached') {
      ablyChannel.current?.detach()
    }
  }

  // Initialize Ably
  useEffect(() => {
    // Existing chat data should be fetched before initiating Ably
    if (!ready) return
    if (initialized.current) return

    const options: Ably.Types.ClientOptions = {
      key: 'MnE7Lw.0ETbXw:XOLUa7OUAHCGAW0f',
      clientId: user?.id,
    }
    const ably = new Ably.Realtime.Promise(options)
    ablyChannel.current = ably.channels.get(pushChannel)

    ablyChannel.current
      ?.attach()
      .then(() => {
        initialized.current = true
        ablyChannel.current?.subscribe.apply(ablyChannel.current, [onMessage])
      })
      .catch((err) => console.error(err))
  }, [onMessage, pushChannel, ready, user?.id])

  return {
    attachAbly,
    detachAbly,
  }
}

export default useAbly
