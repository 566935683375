import { Text } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'

const ClubBookTaskDetailSection = ({
  header,
  body,
}: {
  header: string | React.ReactChild
  body: string | React.ReactChild
}) => {
  const { theme } = useTeams()

  return (
    <div>
      <Text size="small" weight="bold" color={theme.siteVariables.bodyColor}>
        {header}
      </Text>
      <br />
      <Text size="medium" color={theme.siteVariables.bodyColor}>
        {body}
      </Text>
    </div>
  )
}

export default ClubBookTaskDetailSection
