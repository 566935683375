import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChatContext } from '@fable/chat'
import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Button, CloseIcon, Text } from '@fluentui/react-northstar'
import ChatPaneWindow from 'components/chat/ChatPaneWindow'
import { useTeams } from 'hooks/useTeams'
import useNavigation from 'hooks/useNavigation'

const ChatPane = () => {
  const navigate = useNavigate()
  const { mediaQueries } = useTheme()
  const { theme } = useTeams()
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { openedThreads } = useContext(ChatContext)
  const { urlRoomName } = useNavigation()

  const { grey } = theme.siteVariables.colorScheme
  const openedThread = openedThreads?.length ? openedThreads[0] : null

  return (
    <FlexBox
      flexDirection="column"
      className={css`
        position: relative;
        min-height: calc(100vh - 50px);
        ${mediaQueries.tablet} {
          min-height: calc(100vh - 115px);
        }
      `}
    >
      {!openedThread && (
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          className={css`
            position: relative;
            width: 100%;
            height: 56px;
            background: ${grey.background};
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
              0px 1px 2px rgba(0, 0, 0, 0.14);
            z-index: 1;
          `}
        >
          <Text
            className={css`
              width: calc(100% - 60px);
              text-overflow: ellipsis;
              overflow: hidden;
              padding-left: 16px;
            `}
            size="large"
            color={grey.foreground}
          >
            {urlRoomName}
          </Text>
          <Button
            onClick={() => navigate(-1)}
            text
            flat
            iconOnly
            icon={<CloseIcon />}
            className={css`
              position: absolute;
              top: 0;
              right: 8px;
              height: 100%;
            `}
          />
        </FlexBox>
      )}
      {/* This instance of ChatPaneWindow is a thread */}
      {openedThread && (
        <ChatPaneWindow
          isThread
          className={css`
            position: absolute;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            background: ${grey.backgroundActive};
            .chat-message:first-of-type {
              border-bottom: 1px solid ${grey[200]};
            }
          `}
        />
      )}
      {/* This instance of ChatPaneWindow contains the channel messages */}
      <ChatPaneWindow
        className={
          !!openedThread
            ? css`
                background: ${grey.backgroundActive};
                opacity: 0;
              `
            : ''
        }
      />
    </FlexBox>
  )
}

export default ChatPane
