import { useContext, useRef, useState } from 'react'
import { css, cx, useTheme } from '@fable/theme'
import { FlexBox } from '@fable/components'
import { useClickOutside } from '@fable/hooks'
import { EmojiString, Reaction } from '@fable/types'
import emojiPlaceholder from '@fable/utils/bin/assets/emoji/emoji-placeholder.svg'
import { Message } from '../chatTypes'
import { ChatContext } from '../chat_context'
import ChatMessageEmoji from './ChatMessageEmoji'
import { chatEmoji } from '@fable/utils'

const ChatMessageEmojiRow = ({
  message,
  emojiSize = 18,
}: {
  message: Message
  emojiSize?: number
}) => {
  const ref = useRef(null)
  const { nativeOverrides } = useTheme()
  const { deleteReaction, sendReaction } = useContext(ChatContext)

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const chatEmojiKeys = useRef<EmojiString[]>(
    Object.keys(chatEmoji) as EmojiString[]
  )

  useClickOutside(ref, () => {
    setShowEmojiPicker(false)
  })

  const reactions = message.reactions || []

  const handleClickNewEmoji = () => {
    setShowEmojiPicker((prevState) => !prevState)
  }

  const handleToggleReaction = (reaction: Reaction) => {
    if (reaction.state) {
      deleteReaction({ message, emoji: reaction.content })
    } else {
      sendReaction({ message, emoji: reaction.content })
    }

    setShowEmojiPicker(false)
  }

  const allEmoji = chatEmojiKeys.current.map((emoji: EmojiString) => {
    const match = reactions.find(
      (reaction: Reaction) => reaction.content === emoji
    )

    return {
      count: match?.count || 0,
      state: match?.state || false,
      content: emoji,
    }
  }) as Reaction[]

  const activeEmoji = reactions.filter((reaction) => reaction.count > 0)

  const sortEmoji = (array: Reaction[]) =>
    array
      .sort((a, b) => {
        if (a.content > b.content) return -1
        if (a.content < b.content) return 1

        return 0
      })
      .sort((a, b) => {
        if (a.count > b.count) return -1
        if (a.count < b.count) return 1

        return 0
      })

  const renderEmoji = (emoji: Reaction[], style = '') => (
    <FlexBox
      className={cx(
        css`
          gap: ${emojiSize - 2}px;
        `,
        style
      )}
    >
      {sortEmoji(emoji).map((reaction) => (
        <ChatMessageEmoji
          key={`${reaction.message_id}-${reaction.content}`}
          emojiSizes={{
            width: `${emojiSize}px`,
            height: `${emojiSize}px`,
          }}
          reaction={reaction}
          onToggleReaction={handleToggleReaction}
        />
      ))}
    </FlexBox>
  )

  return (
    <div
      ref={ref}
      className={css`
        position: relative;
      `}
    >
      {showEmojiPicker &&
        renderEmoji(
          allEmoji,
          css`
            position: absolute;
            bottom: 20px;
            left: -16px;
            background: white;
            border-radius: 10px;
            padding: 5px;
            z-index: 5;
          `
        )}
      <FlexBox>
        {renderEmoji(activeEmoji)}
        <button
          data-testid="addEmoji"
          className={nativeOverrides.buttonClear}
          onClick={handleClickNewEmoji}
        >
          <FlexBox
            centerAll
            className={cx(
              css`
                width: ${emojiSize + 2}px;
                height: ${emojiSize + 2}px;
              `,
              activeEmoji?.length > 0 &&
                css`
                  margin-left: 14px;
                `
            )}
          >
            <img
              className={css`
                width: 100%;
                height: 100%;
              `}
              src={emojiPlaceholder}
              alt="Emoji placeholder. Click to add your reaction"
            />
          </FlexBox>
        </button>
      </FlexBox>
    </div>
  )
}

export default ChatMessageEmojiRow
