import { Folio } from '@fable/types'
import { FlexBox, RoundImage } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { Button, List, Text, OpenOutsideIcon } from '@fluentui/react-northstar'
import formatSkeletonList from 'utils/formatSkeletonList'
import FolioBookCarousel from './FolioBookCarousel'
import { useTeams } from 'hooks/useTeams'

const FolioList = ({
  loading,
  listData,
}: {
  loading: boolean
  listData: Folio[]
}) => {
  const { mediaQueries } = useTheme()
  const { theme } = useTeams()

  const formatCarouselList = () =>
    listData.map((folio) => ({
      key: folio.id,
      content: (
        <FlexBox
          alignItems="center"
          className={css`
            width: 100%;
            padding-bottom: 18px;
          `}
        >
          <FlexBox
            flexDirection="column"
            alignItems="center"
            className={css`
              max-width: 144px;
              padding: 0 25px;
            `}
          >
            <RoundImage
              size={94}
              src={folio.curator.image}
              alt={folio.curator.display_name}
              className={css`
                margin-bottom: 9px;
                width: 70px;
                height: 70px;
                ${mediaQueries.mobile} {
                  width: 94px;
                  height: 94px;
                }
              `}
            />
            <Text
              color={theme.siteVariables.bodyColor}
              data-testid="folioDisplayName"
              styles={{ display: 'block' }}
              align="center"
              weight="bold"
            >
              {folio.curator.display_name}'s
            </Text>
            <Text
              color={theme.siteVariables.bodyColor}
              align="center"
              weight="bold"
            >
              Favorite Books
            </Text>
            <Button
              onClick={() =>
                window.open(`http://fable.co/folio/${folio.slug}`, '_blank')
              }
              styles={{
                width: 'fit-content',
                height: '24px',
                marginTop: '9px',
              }}
              icon={<OpenOutsideIcon />}
              iconPosition="after"
              content="Details"
            />
          </FlexBox>
          <FolioBookCarousel
            books={folio?.folio_books?.map(
              (folioBookObject) => folioBookObject.book
            )}
          />
        </FlexBox>
      ),
    }))

  return (
    <List
      className={css`
        .ui-list__item__contentWrapper {
          display: block;
        }
        .ui-list__item {
          padding: 0;
        }
      `}
      items={loading ? formatSkeletonList() : formatCarouselList()}
    />
  )
}

export default FolioList
