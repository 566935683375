import { useContext } from 'react'
import { css, useTheme } from '@fable/theme'
import ChatPane from 'components/chat/ChatPane'
import { ClubContext } from 'utils/ClubContext'
import { useTeams } from 'hooks/useTeams'

const ChannelTabRight = () => {
  const { mediaQueries } = useTheme()
  const { theme } = useTeams()
  const { showChat } = useContext(ClubContext)

  return showChat ? (
    <div
      className={css`
        position: absolute;
        width: 100%;
        height: calc(100vh - 50px);
        min-height: 650px;
        top: 48px;
        flex: 1 0 auto;
        background: ${theme.siteVariables.colorScheme.grey.background};
        z-index: 2;
        > div {
          width: inherit;
          height: 100%;
        }
        ${mediaQueries.tablet} {
          width: unset;
          position: unset;
          height: unset;
        }
      `}
    >
      <ChatPane />
    </div>
  ) : null
}

export default ChannelTabRight
