import { User } from '@fable/types'
import ClubList from 'components/club/ClubList'
import { getCompanyClubs } from 'utils/query'

const EMPTY_STATE = `It looks like your organization has not started any clubs yet. 
Any book clubs belonging to your organization will show up here.`

const FindClubsList = ({ user }: { user: User }) => {
  return (
    <ClubList
      user={user}
      emptyStateText={EMPTY_STATE}
      query={getCompanyClubs}
    />
  )
}

export default FindClubsList
