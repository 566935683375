import { useContext } from 'react'
import { ChatContext, MilestoneDiscussion, Room } from '@fable/chat'
import { FlexBox } from '@fable/components'
import { css } from '@fable/theme'
import { Text, List, ChevronEndIcon } from '@fluentui/react-northstar'
import pluralize from 'pluralize'
import { ClubContext } from 'utils/ClubContext'
import useNavigation from 'hooks/useNavigation'
import { useTeams } from 'hooks/useTeams'

const ChannelTabDiscussionsRoomsList = ({
  milestone,
}: {
  milestone: MilestoneDiscussion
}) => {
  const { theme } = useTeams()
  const { updatePath } = useNavigation()
  const { setShowChat } = useContext(ClubContext)
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { enterRoom, closeThread, currentRoom } = useContext(ChatContext)

  const rooms = milestone?.rooms
  const roomIds = rooms?.map((room: Room) => room.room_id)
  const selectedIndex = roomIds?.indexOf(currentRoom?.room_id || '')

  const handleSelectedIndexChange = (newIndex = 0) => {
    const room = rooms[newIndex]

    updatePath({ milestone, roomTitle: rooms[newIndex].title })

    setShowChat({
      show: true,
      callback: () => {
        closeThread()
        enterRoom({
          room,
        })
      },
    })
  }

  return (
    <div
      className={css`
        li.ui-list__item {
          border-top: 1px solid ${theme.siteVariables.colors.grey[100]};
          padding-left: 0;
        }
        .ui-list__itemheadermedia,
        li:hover .ui-list__itemheadermedia {
          color: ${theme.siteVariables.colorScheme.grey.foregroundActive};
          background: ${theme.siteVariables.colorScheme.grey.backgroundActive};
          width: unset;
          height: unset;
          margin: unset;
          position: unset;
        }
      `}
    >
      <List
        selectable
        selectedIndex={selectedIndex}
        onSelectedIndexChange={(_, newProps) => {
          handleSelectedIndexChange(newProps?.selectedIndex)
        }}
        items={rooms?.map((room: Room) => ({
          key: room.room_id,
          header: (
            <Text
              color={theme.siteVariables.bodyColor}
              size="small"
              weight="bold"
            >
              {room.title}
            </Text>
          ),
          headerMedia:
            room.unread_count > 0 ? (
              <FlexBox justifyContent="flex-end" alignItems="center">
                {pluralize('new post', room.unread_count, true)}
                <ChevronEndIcon />
              </FlexBox>
            ) : null,
        }))}
      />
    </div>
  )
}

export default ChannelTabDiscussionsRoomsList
