import { FlexBox } from '@fable/components'
import { Card, Text } from '@fluentui/react-northstar'
import { css, useTheme } from '@fable/theme'
import peopleWithShapes from 'assets/graphics/people-with-shapes.png'
import invokeTask from 'utils/invokeTask'
import { useContext } from 'react'
import { ClubContext } from 'utils/ClubContext'
import { useTeams } from 'hooks/useTeams'

const ChannelTabAddBookCard = () => {
  // @ts-ignore
  const { refetchAll, setShowChat } = useContext(ClubContext)
  const { colors } = useTheme()
  const { mediaQueries } = useTheme()
  const { theme } = useTeams()

  return (
    <Card
      onClick={() =>
        invokeTask({
          title: 'Choose your book',
          slug: 'add-book',
          onSuccess: () => {
            setShowChat({ show: false, callback: refetchAll })
          },
        })
      }
      inverted
      className={css`
        &.ui-card {
          border-top: 2px solid ${colors.fableGreen};
          width: 100%;
          ${mediaQueries.tabletL} {
            width: 415px;
          }
        }
      `}
    >
      <FlexBox alignItems="center">
        <img
          className={css`
            margin-right: 7px;
            width: 22.32%;
            min-width: 77px;
            flex: 1 0 auto;
            max-width: 146px;
            ${mediaQueries.tabletL} {
              margin-right: 20px;
            }
          `}
          src={peopleWithShapes}
          alt="People chatting with shapes"
        />
        <div>
          <Text
            color={theme.siteVariables.bodyColor}
            className={css`
              &.ui-text {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                ${mediaQueries.mobile} {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 24px;
                }
              }
            `}
            size="large"
            weight="semibold"
          >
            Add a book to create your Milestones
          </Text>
          <Text size="small" color={theme.siteVariables.bodyColor}>
            You’ll automattically see Start, Finish, and optional chapter-based
            milestones.
          </Text>
        </div>
      </FlexBox>
    </Card>
  )
}

export default ChannelTabAddBookCard
