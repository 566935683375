import { ThemeProvider, theme } from '@fable/theme'
import AppWrapper from 'AppWrapper'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import { Debug } from './Debug'
import LinkToMicrosoftTask from './components/sign_in_task/LinkToMicrosoftTask'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)


// TODO: React.StrictMode was breaking fluentui style rendering in dev mode.
// Consider bringing back StrictMode if we upgrade from fluent-northstar to fluentui v9 and they've fixed these problems
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <React.StrictMode>
            <Routes>
              <Route
                path="/link-to-microsoft/"
                element={<LinkToMicrosoftTask />}
              />
              <Route path="*" element={<App />} />
            </Routes>
          </React.StrictMode>
        </AppWrapper>
        <Debug />
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
)
