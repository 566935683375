import { useContext } from 'react'
import { css, useTheme } from '@fable/theme'
import { ChatContext } from '../chat_context'
import { Attachment } from '../chatTypes'
import { Button, CloseIcon } from '@fable/components'

const ChatMessageAttachmentPhoto = ({
  image,
}: {
  /** @param {Attachment} image - The attachment info for a type "photo" */
  image: Attachment
}) => {
  const { setModal } = useContext(ChatContext)
  const { nativeOverrides } = useTheme()

  const handleClickPhoto = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    url: string
  ) => {
    e.preventDefault()

    setModal({
      show: true,
      component: (
        <div
          className={css`
            background-color: white;
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              negative
              onClick={() => setModal({ show: false })}
              className={css`
                margin: 2px 2px 0px 0px;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.4);
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
                border-radius: 100%;
                transition: 0.2s;
                cursor: pointer;
                background: white;
                &:hover {
                  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                  transform: scale(1.05);
                }
              `}
            >
              <CloseIcon />
            </Button>
          </div>
          <img
            data-testid="fullsize"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className={css`
              height: calc(100% - 20%);
              margin-top: 50px;
            `}
            src={url}
            alt="attachment full size"
          />
        </div>
      ),
    })
  }

  return (
    <div>
      <button
        className={nativeOverrides.buttonClear}
        onClick={(e) => handleClickPhoto(e, image.url)}
      >
        <img
          className={css`
            width: 100%;
          `}
          data-testid="inMessageThumbnail"
          src={image.thumbnail_url}
          alt="attachment"
        />
      </button>
    </div>
  )
}

export default ChatMessageAttachmentPhoto
