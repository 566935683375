import { app } from '@microsoft/teams-js'

interface NavigationParams {
  appId: string
  pageId: string
  subPageId?: string
  channelId?: string
  webUrl?: string
}

function createTeamsAppLink(params: NavigationParams) {
  const url = new URL(
    'https://teams.microsoft.com/l/entity/' +
      encodeURIComponent(params.appId) +
      '/' +
      encodeURIComponent(params.pageId)
  )

  if (params.webUrl) {
    url.searchParams.append('webUrl', params.webUrl)
  }
  if (params.channelId || params.subPageId) {
    url.searchParams.append(
      'context',
      JSON.stringify({
        channelId: params.channelId,
        subEntityId: params.subPageId,
      })
    )
  }
  return url.toString()
}

export async function executeTeamsDeepLink(params: NavigationParams) {
  const url = createTeamsAppLink(params)

  console.log('executeTeamsDeepLink', url)
  try {
    await app.openLink(url)
  } catch (e) {
    console.error(`Failed to open deep link ${url}`, e)
  }
}
