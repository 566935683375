import { useContext, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { FlexBox } from '@fable/components'
import { css, useTheme } from '@fable/theme'
import { ChatContext } from '@fable/chat'
import {
  Datepicker,
  DatepickerProps,
  Dropdown,
  DropdownProps,
  Label,
  Text,
} from '@fluentui/react-northstar'
import { dialog } from '@microsoft/teams-js'
import range from 'lodash/range'
import format from 'date-fns/format'
import pluralize from 'pluralize'
import BookDetailBanner from 'components/BookDetailBanner'
import TaskBody from 'components/task/TaskBody'
import { useTeams } from 'hooks/useTeams'
import TaskHeader from 'components/task/TaskHeader'
import { createMilestoneWithWizard } from 'utils/query'

interface MilestoneDataToSend {
  start_date: Date
  num_milestones: number
  duration_in_days: number
}

const MilestoneTask = () => {
  const dateFormat = 'yyyy-LL-dd'
  const { mediaQueries } = useTheme()
  const { theme } = useTeams()
  const { clubDetailsQuery, milestoneDiscussionsQuery } =
    // TypeScript only throws errors for this in Teams, not in our web app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useContext(ChatContext)
  const [durationItems, setDurationItems] = useState<
    { durationString: string; duration_in_days: number }[] | undefined
  >()
  const [newMilestoneData, setNewMilestoneData] = useState<MilestoneDataToSend>(
    {
      start_date: new Date(),
      num_milestones: 1,
      duration_in_days: 30,
    }
  )

  const club = clubDetailsQuery?.data?.data

  const milestoneDropdownItems = useMemo(
    () =>
      range(club?.current_book?.chapter_count || 0)
        .map((num) => num + 1)
        // There's a max of 30 milestones if a book has more than 30 chapters
        .slice(0, 30),
    [club?.current_book?.chapter_count]
  )

  const milestoneInfoText = useMemo(() => {
    const { num_milestones } = newMilestoneData
    const chapterCount = club?.current_book?.chapter_count || 0
    const count = Math.round(chapterCount / num_milestones)

    return `About ${pluralize('chapter', count, true)} per Milestone`
  }, [club?.current_book?.chapter_count, newMilestoneData])

  const createMilestone = useMutation('createMilestone', async () =>
    createMilestoneWithWizard({
      clubId: club?.id || '',
      clubBookId: club?.current_club_book?.id || '',
      body: {
        ...newMilestoneData,
        start_date: format(newMilestoneData.start_date, dateFormat),
      },
    })
  )

  const updateMilestoneData = (obj: Partial<MilestoneDataToSend>) => {
    setNewMilestoneData((prev) => ({
      ...prev,
      ...obj,
    }))
  }

  const handleClickCreateMilestone = async () => {
    try {
      await createMilestone.mutateAsync()
      dialog.url.submit()
    } catch (error) {
      console.error(error)
    }
  }

  // Commented out until design is ready
  // const handleClickCreateManually = () => {
  //   location.push('create-milestone/manual');
  // };

  useEffect(() => {
    const array: string[] = ['2 weeks']

    // This function creates items for up to 4 months by every two weeks
    // and then sets the result to state to rerender the dropdown component with new data
    const createDurationItems = (last: number, max = 4): void => {
      const next = last + 0.5

      if (last === max) {
        setDurationItems(
          array.map((durationString: string) => ({
            durationString,
            duration_in_days:
              durationString === '2 weeks'
                ? 14
                : 30 * parseFloat(durationString),
          }))
        )

        return
      }

      array.push(pluralize('month', next, true))
      return createDurationItems(next)
    }

    createDurationItems(0.5)
  }, [])

  const loading =
    createMilestone.isLoading || milestoneDiscussionsQuery?.isRefetching

  return (
    <TaskBody
      buttons={[
        // Commented out until design is ready
        // {
        //   type: 'secondary',
        //   onClick: handleClickCreateManually,
        //   title: 'Create manually',
        // },
        {
          type: 'primary',
          onClick: handleClickCreateMilestone,
          title: 'Create Milestone',
          loading,
          disabled: loading,
        },
      ]}
    >
      <div>
        <div
          className={css`
            text-align: center;
            margin: 0 auto;
          `}
        >
          <TaskHeader
            title="Chapter Milestones"
            detail="How many Chapter Milestones should we break the book into?"
          />
        </div>
        {club?.current_book && (
          <BookDetailBanner
            book={club.current_book}
            className={css`
              margin: 27px auto 15px auto;
              height: 168px;
              flex-direction: row-reverse;
              justify-content: flex-end;
              padding: 0 min(6.14%, 30px);
            `}
            bookStyles={css`
              width: 115px;
              height: 185px;
            `}
            header={club.current_book.title}
            description={pluralize(
              'Chapter',
              club.current_book.chapter_count,
              true
            )}
          />
        )}
      </div>
      <FlexBox
        className={css`
          gap: 15px;
          padding: 0 17px;
          ${mediaQueries.mobile} {
            padding: 0 27px;
          }
        `}
        flexDirection="column"
      >
        <FlexBox
          flexWrap="wrap"
          className={css`
            width: 100%;
            gap: 15px;
            > div {
              flex: 1 1 208px;
            }
          `}
        >
          <div>
            <Label
              styles={{
                whiteSpace: 'nowrap',
                marginBottom: '5px',
              }}
              color="white"
            >
              Start Date
            </Label>
            <Datepicker
              allowManualInput={false}
              minDate={new Date()}
              selectedDate={newMilestoneData.start_date}
              onDateChange={(
                _: unknown,
                data: (DatepickerProps & { value: Date }) | undefined
              ) =>
                updateMilestoneData({
                  // data.value is a Date object
                  start_date: data?.value,
                })
              }
            />
          </div>
          <div>
            <Label styles={{ marginBottom: '5px' }} color="white">
              Duration
            </Label>
            <Dropdown
              className={css`
                * {
                  text-transform: capitalize;
                }
              `}
              fluid
              items={durationItems?.map(({ durationString }) => durationString)}
              activeSelectedIndex={durationItems?.findIndex(
                ({ duration_in_days }) =>
                  duration_in_days === newMilestoneData.duration_in_days
              )}
              onChange={(_: unknown, data: DropdownProps) =>
                updateMilestoneData({
                  duration_in_days:
                    data.value === '2 weeks'
                      ? 14
                      : 30 * parseFloat(`${data.value}`),
                })
              }
              value={
                durationItems?.find(
                  ({ duration_in_days }) =>
                    duration_in_days === newMilestoneData.duration_in_days
                )?.durationString
              }
            />
          </div>
        </FlexBox>
        <div
          className={css`
            margin-right: 15px;
          `}
        >
          <Label
            styles={{
              whiteSpace: 'nowrap',
              marginBottom: '5px',
            }}
            color="white"
          >
            Number of Milestones
          </Label>
          <FlexBox
            alignItems="center"
            flexWrap="wrap"
            className={css`
              column-gap: 15px;
              row-gap: 5px;
            `}
          >
            <Dropdown
              fluid
              items={milestoneDropdownItems}
              activeSelectedIndex={milestoneDropdownItems.indexOf(
                newMilestoneData.num_milestones
              )}
              onChange={(_: unknown, data: DropdownProps) =>
                updateMilestoneData({
                  num_milestones: parseInt(data.value as string),
                })
              }
              value={newMilestoneData.num_milestones}
            />
            <Text
              styles={{
                color: theme.siteVariables.colors.brand[700],
              }}
              size="small"
            >
              {milestoneInfoText}
            </Text>
          </FlexBox>
        </div>
      </FlexBox>
    </TaskBody>
  )
}

export default MilestoneTask
