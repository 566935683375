import { FlexBox, RoundImage } from '@fable/components'
import { css } from '@fable/theme'
import { Card, Text } from '@fluentui/react-northstar'
import pluralize from 'pluralize'
import { useTeams } from 'hooks/useTeams'
import { useContext } from 'react'
import { ClubContext } from 'utils/ClubContext'
import ClubMastheadBanner from 'components/club/ClubMastheadBanner'

const ClubMasthead = () => {
  const { theme } = useTeams()
  const { tenantClub } = useContext(ClubContext)

  const { grey } = theme.siteVariables.colors

  return (
    <Card elevated fluid styles={theme.siteVariables.noHover}>
      <ClubMastheadBanner />
      <div
        data-testid="clubMasthead"
        className={css`
          text-align: center;
          margin: 0 auto 19px auto;
        `}
      >
        <div
          className={css`
            margin: 12px auto 6px auto;
          `}
        >
          <Text
            weight="bold"
            size="large"
            color={theme.siteVariables.bodyColor}
          >
            {tenantClub?.title}
          </Text>
        </div>
        <Text>{tenantClub?.feature_text || tenantClub?.seo_description}</Text>
      </div>
      <FlexBox
        centerAll
        className={css`
          margin-bottom: 30px;
        `}
      >
        <FlexBox
          className={css`
            margin-right: 20px;
          `}
        >
          <div
            className={css`
              width: 40px;
              height: 56px;
              margin-right: 14px;
              filter: drop-shadow(0px 0px 1.51899px rgba(0, 0, 0, 0.12))
                drop-shadow(0px 1.51899px 3.03797px rgba(0, 0, 0, 0.14));
              border-radius: 2.27848px;
              background: left / cover no-repeat
                url(${tenantClub?.current_book?.cover_image_small});
            `}
          />
          <div>
            <Text
              weight="bold"
              styles={{ fontSize: '16px', lineHeight: '24px' }}
              color={theme.siteVariables.bodyColor}
            >
              Club Book
            </Text>
            <br />
            <Text
              size="small"
              weight="bold"
              color={theme.siteVariables.bodyColor}
            >
              {tenantClub?.current_book?.title}
            </Text>
            <br />
            <Text size="small" color={theme.siteVariables.bodyColor}>
              {tenantClub?.current_book?.authors[0]?.name}
            </Text>
          </div>
        </FlexBox>
        <FlexBox
          className={css`
            margin-right: 33px;
          `}
        >
          <RoundImage
            className={css`
              margin-right: 18px;
            `}
            size={41}
            src={`${tenantClub?.creator?.pic_resize}?w=134`}
            alt="Moderator pic"
          />
          <div>
            <Text color={grey[450]} size="small">
              Moderated by
            </Text>
            <br />
            <Text
              weight="bold"
              styles={{ fontSize: '16px', lineHeight: '24px' }}
              color={theme.siteVariables.bodyColor}
            >
              {tenantClub?.creator?.display_name}
            </Text>
          </div>
        </FlexBox>
        <div>
          <FlexBox
            className={css`
              > div + div {
                margin-left: -8px;
              }
            `}
          >
            {tenantClub?.member_pics_snapshot?.map((pic) => (
              <div data-testid="memberPic" key={pic}>
                <RoundImage key={pic} size={24} src={pic} alt="Member pic" />
              </div>
            ))}
          </FlexBox>
          <Text color={grey[450]} size="small">
            {pluralize('reader', tenantClub?.member_count, true)}
          </Text>
        </div>
      </FlexBox>
    </Card>
  )
}

export default ClubMasthead
