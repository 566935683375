import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  useQueryClient,
} from 'react-query'
import { Club, Reaction, User } from '@fable/types'
import {
  messagePost,
  milestoneDiscussionsGet,
  milestonesGet,
  removeMessage,
  appendMessage,
  messageDelete,
  hasMessage,
  updateMessage,
  clubMembersGet,
  clubDetailsGet,
  reactionDelete,
  reactionPost,
  clubBookGet,
  markRoomAsRead,
  debounce,
  isUrl,
  getInfiniteQueryParam,
  messagesGet,
  messageSingleGet,
  updateReaction,
} from '../lib/chatUtils'
import {
  Message,
  MessagesQuery,
  MessagePostBody,
  SendMessageMutation,
  AblyMessage,
  Attachment,
  ModalArgs,
  OpenedThread,
  ChatParams,
  Room,
  MilestoneDiscussionsQuery,
  Lobby,
} from '../chatTypes'
import { useNotifications } from '@fable/hooks'
import { EmojiString, FileObject } from '@fable/types'
import useAbly from './useAbly'

const useChat = ({
  user,
  allowNotifications,
}: {
  user: User
  allowNotifications?: boolean
}) => {
  const chatQueryClient = useQueryClient()
  const notify = useNotifications({ disabled: !allowNotifications })

  // Notifies component of latest message received
  const newMessage = useRef<Message | undefined>(undefined)

  // clubSlug is used to fetch club info, book, milestones, discussions, etc.
  const [image, setImage] = useState<FileObject | null>(null)
  const [clubSlug, setClubSlug] = useState('')
  const [ablyMessage, setAblyMessage] = useState<Partial<AblyMessage> | null>(
    null
  )
  const [currentRoom, setCurrentRoom] = useState<Room | Lobby | null>(null)
  const [openedThreads, setopenedThreads] = useState<OpenedThread[] | null>(
    null
  )
  const [modal, setModal] = useState<ModalArgs>({
    show: false,
    component: null,
  })
  const [error, setError] = useState<any>(undefined)
  const [chatParams, setChatParams] = useState<ChatParams>({
    roomId: '',
    threadParentType: undefined,
    threadParentId: '',
    threadParentContentId: '',
  })

  // useErrorModal(error);

  const defaultQueryOptions: Omit<
    UseQueryOptions<any, unknown, any, any>,
    'queryKey' | 'queryFn'
  > = {
    keepPreviousData: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    enabled: false,
    onError: (err) => {
      setError(err)
      console.error(err)
    },
  }

  // This is the source for all data in other calls. Without club details, milestones (chat rooms) cannot be fetched
  const clubDetailsQuery = useQuery(
    ['clubDetails', clubSlug],
    async () => await clubDetailsGet(clubSlug),
    {
      ...defaultQueryOptions,
      onSuccess: () => {
        attachAbly()
      },
      keepPreviousData: false,
      cacheTime: 0,
    }
  )

  const club = clubDetailsQuery.data?.data as Club
  const clubId = club?.id || ''
  const clubBookId = club?.current_club_book?.id || ''

  const clubBookQuery = useQuery(
    ['clubBooks', clubId, clubBookId],
    async () =>
      await clubBookGet({
        clubId,
        clubBookId,
      }),
    { ...defaultQueryOptions, enabled: !!clubId && !!clubBookId }
  )

  // Milestone discussions include some basic milestone information as well as rooms for each milestone, but no messages
  const milestoneDiscussionsQuery = useQuery(
    'milestoneDiscussions',
    async () =>
      await milestoneDiscussionsGet({
        clubId,
        clubBookId,
      }),
    {
      ...defaultQueryOptions,
      enabled: !!clubBookId,
      select: (data: MilestoneDiscussionsQuery) => {
        // Makes it easier to spot which data item is the lobby without using indices or existence of other fields
        data.data.lobby.isLobby = true

        return data
      },
    }
  )

  // This API came before the discussions query above which contains more information.
  // Before you would have to call milestones and then get rooms for each milestone
  const milestonesQuery = useQuery(
    'milestones',
    async () => await milestonesGet({ clubId, clubBookId }),
    {
      ...defaultQueryOptions,
      onSettled: () => {
        attachAbly()
      },
    }
  )

  const messageSingleQuery = useQuery(
    ['messageSingle', chatParams],
    async () =>
      await messageSingleGet({
        clubId,
        clubBookId,
        messageId: chatParams.threadParentId || '',
      }),
    {
      ...defaultQueryOptions,
      enabled: !!chatParams.threadParentId,
      // Without these settings, emoji will lag if you interact with them from a room and then click into a thread
      keepPreviousData: false,
      cacheTime: 0,
    }
  )

  // This is the query to get all chat messages for a given room or thread.
  // The chatParams must be the same (order doesn't matter since it's an object)
  // otherwise react-query will return undefined when trying to access its cache from one of its methods
  const messagesQuery = useInfiniteQuery(
    ['messages', chatParams],
    async ({ pageParam }) =>
      await messagesGet({
        currentRoomId: chatParams.roomId,
        pageParam,
        threadParentId: chatParams.threadParentId,
        threadParentContentId: chatParams.threadParentContentId,
        threadParentType: chatParams.threadParentType,
        clubId,
        clubBookId,
      }),
    {
      ...defaultQueryOptions,
      enabled: !!chatParams.roomId,
      select: (data) => {
        const pages = data.pages
        const threadParent = chatParams.threadParentId
          ? messageSingleQuery?.data?.data
          : null

        if (
          !!threadParent &&
          !pages[0].results.find(
            (message: Message) => message.id === threadParent.id
          )
        ) {
          pages[0].results.push(threadParent)
        }

        return {
          /**
           * Using the method suggested in the react-query docs to reverse pages and content
           * resulted in content being reversed twice, which basically reset the order
           *
           * See exports at end of file for flattened list
           */
          pages,
          // This params must be reversed for reverse pagination
          pageParams: [...data.pageParams].reverse(),
        }
      },
      getNextPageParam: getInfiniteQueryParam,
    }
  )

  const setMessageQueryCache = useCallback(
    (callback: (params: any) => void) =>
      chatQueryClient.setQueryData(['messages', chatParams], (cache: any) => {
        // If you're switching between rooms really fast, cache might not exist by the time Ably comes back with a message
        if (!!cache) return callback(cache)
      }),
    [chatParams, chatQueryClient]
  )

  const { attachAbly, detachAbly } = useAbly({
    user,
    ready: !!club?.push_channel,
    pushChannel: club?.push_channel || '',
    onMessage: (message: Partial<AblyMessage>) => {
      setAblyMessage(message)
    },
  })

  const clubMembersQuery = useQuery(
    'clubMembers',
    async () => await clubMembersGet(clubId),
    {
      ...defaultQueryOptions,
      onError: defaultQueryOptions.onError,
      enabled: !!clubId,
    }
  )

  const deleteReaction = useMutation(
    async ({ message, emoji }: { message: Message; emoji: EmojiString }) =>
      await reactionDelete({ messageId: message.id, emoji }),
    {
      onMutate: async ({ message, emoji }) => {
        detachAbly()
        const previousData: MessagesQuery | undefined =
          chatQueryClient.getQueryData(['messages', chatParams])

        let reaction = (message.reactions || []).find(
          (reaction: Reaction) => reaction.content === emoji
        ) as Reaction

        if (!!reaction) {
          reaction.count -= 1
          reaction.state = false
        }

        setMessageQueryCache((cache: any) =>
          updateReaction({
            cache,
            reaction,
            type: 'reaction.deleted',
          })
        )

        return { previousData }
      },
      // https://react-query.tanstack.com/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
      onError: (err, id, context: any) => {
        // put the message back
        chatQueryClient.setQueryData(
          ['messages', chatParams],
          context.previousData
        )

        setError(err)
      },
      onSettled: () => {
        attachAbly()
      },
    }
  )

  const sendReaction = useMutation(
    async ({ message, emoji }: { message: Message; emoji: EmojiString }) =>
      await reactionPost({ messageId: message.id, emoji }),
    {
      onMutate: async ({ message, emoji }) => {
        detachAbly()
        const previousData: MessagesQuery | undefined =
          chatQueryClient.getQueryData(['messages', chatParams])

        let reaction = (message.reactions || []).find(
          (reaction: Reaction) => reaction.content === emoji
        ) as Reaction

        if (!!reaction) {
          reaction.count += 1
          reaction.state = true
        } else {
          reaction = {
            message_id: message.id,
            content: emoji,
            count: 1,
            state: true,
          }
        }

        setMessageQueryCache((cache: any) =>
          updateReaction({
            cache,
            reaction,
            type: 'reaction.created',
          })
        )

        return { previousData }
      },
      // https://react-query.tanstack.com/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
      onError: (err, id, context: any) => {
        // put the message back
        chatQueryClient.setQueryData(
          ['messages', chatParams],
          context.previousData
        )

        setError(err)
      },
      onSettled: () => {
        attachAbly()
      },
    }
  )

  const deleteMessage = useMutation(
    async (message: Message) =>
      await messageDelete({
        id: message.id,
        clubId,
        clubBookId,
      }),
    {
      onMutate: async (message: Message) => {
        detachAbly()

        const previousData: MessagesQuery | undefined =
          chatQueryClient.getQueryData(['messages', chatParams])

        setMessageQueryCache((cache: any) => removeMessage({ message, cache }))

        if (
          message.id ===
          openedThreads?.find((thread) => thread.threadParentId)?.threadParentId
        ) {
          chatQueryClient.setQueryData(
            [
              'messages',
              {
                // This should not use chatParams directly because the thread parent info must be blank
                // in order to remove the message from the room cache
                roomId: chatParams.roomId,
                threadParentId: '',
                threadParentType: undefined,
              },
            ],
            (cache: any) => removeMessage({ message, cache })
          )
        }

        return { previousData }
      },
      // https://react-query.tanstack.com/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
      onError: (err, id, context: any) => {
        // put the message back
        chatQueryClient.setQueryData(
          ['messages', chatParams],
          context.previousData
        )

        setError(err)
      },
      onSettled: async (data, error, message) => {
        if (
          message.id ===
          openedThreads?.find((thread) => thread.threadParentId)?.threadParentId
        ) {
          closeThread()
        }
        await messagesQuery.refetch()
        attachAbly()
      },
    }
  )

  const sendMessage = useMutation(
    async ({
      message,
      id,
      mentions,
      hasSpecialMention,
    }: SendMessageMutation) => {
      const body: MessagePostBody = {
        id,
        roomId: chatParams.roomId,
        hasSpecialMention: hasSpecialMention || false,
        ...(message && message.length > 0 && { message }),
        ...(mentions && mentions.length > 0 && { mentions }),
        ...(chatParams.threadParentId && {
          threadParentId:
            chatParams.threadParentType === 'message'
              ? chatParams.threadParentId
              : chatParams.threadParentContentId,
          threadParentType: chatParams.threadParentType,
        }),
        ...(image && { image: image.fileData }),
      }

      return await messagePost({
        clubId,
        clubBookId,
        body,
      })
    },
    {
      onMutate: async (optimisticMessage) => {
        detachAbly()

        chatQueryClient.cancelQueries(['messages', chatParams])

        const previousData: MessagesQuery | undefined =
          chatQueryClient.getQueryData(['messages', chatParams])

        const attachments = []
        if (image?.render) {
          attachments.push({
            type: 'photo',
            media_type: image.fileData?.type,
            thumbnail: image.render,
            thumbnail_url: image.render,
            url: image.render,
            version: '0',
          } as Attachment)
        }

        const pendingLinkUnfurl = !!optimisticMessage.message
          ?.split(' ')
          .some((x) => isUrl(x.replace('\n', '')))

        const messageFormatted: Message = {
          loading: pendingLinkUnfurl,
          created_at: new Date().toISOString(),
          id: optimisticMessage.id,
          room_id: chatParams.roomId,
          text: optimisticMessage.message || '',
          type: 'message',
          mentions: optimisticMessage.mentions || [],
          ...(!!chatParams.threadParentId && {
            thread_parent: {
              id:
                chatParams.threadParentType === 'message'
                  ? chatParams.threadParentId
                  : chatParams.threadParentContentId || '',
              type: chatParams.threadParentType,
            },
          }),
          user,
          ...(!!attachments?.length && { attachments }),
        }

        // Appends new message to react query cache
        setMessageQueryCache((cache: any) =>
          appendMessage({
            cache,
            newMessage: messageFormatted,
          })
        )

        setImage(null)

        return { previousData }
      },
      onError: (err, optimisticMessage, context: any) => {
        // Remove the message that was added optimistically
        chatQueryClient.setQueryData(
          ['messages', chatParams],
          context.previousData
        )
        setError(err)
      },
      onSettled: async () => {
        await messagesQuery.refetch()
        attachAbly()
      },
    }
  )

  const openThread = ({
    threadParentId,
    threadParentContentId = '',
    threadParentType,
  }: OpenedThread) => {
    setChatParams((prev) => ({
      ...prev,
      threadParentId,
      threadParentContentId,
      threadParentType,
    }))

    // As of July 2022, only one thread is allowed to be open at a time.
    setopenedThreads([
      { threadParentId, threadParentType, threadParentContentId },
    ])
  }

  const closeThread = async () => {
    await chatQueryClient.invalidateQueries([['messageSingle', chatParams]])

    setChatParams((prev) => ({
      ...prev,
      threadParentId: '',
      threadParentContentId: '',
      threadParentType: undefined,
    }))

    await messagesQuery.refetch()

    // As of July 2022, only one thread is allowed to be open at a time.
    setopenedThreads([])
  }

  const enterRoom = async ({
    room,
    threadParent,
  }: {
    room: Room | Lobby | null
    threadParent?: Message
  }) => {
    const roomId = room?.room_id || ''
    const params: ChatParams = {
      roomId,
      threadParentId: '',
      threadParentContentId: '',
      threadParentType: undefined,
    }

    if (threadParent) {
      if (threadParent.type !== 'message') {
        params.threadParentContentId = threadParent.content_id || ''
      }
      params.threadParentId = threadParent.id
      params.threadParentType = threadParent?.type
    }

    setChatParams(params)

    if (currentRoom?.room_id !== roomId) {
      setCurrentRoom(room)
    }

    if (roomId) {
      markRoomAsRead({ roomId })
      milestoneDiscussionsQuery.refetch()

      if (params.threadParentId) {
        openThread({
          threadParentId: params.threadParentId,
          threadParentContentId: params.threadParentContentId,
          threadParentType: params.threadParentType,
        })
      }

      messagesQuery.refetch()
    }
  }

  // When Ably is initiated it no longer has access to the data passed to it
  // This is for the best otherwise Ably will be initiated with every render and eventually break
  useEffect(() => {
    if (!ablyMessage) return
    const message = ablyMessage.data?.object as Message
    const reaction = ablyMessage.data?.object as Reaction
    const type = ablyMessage?.data?.type

    if (
      !!reaction?.message_id &&
      (type === 'reaction.created' || type === 'reaction.deleted')
    ) {
      setMessageQueryCache((cache: any) =>
        updateReaction({
          reaction,
          type,
          cache,
        })
      )

      debounce(messagesQuery.refetch, 500)
    }

    if (!!message) {
      if (chatParams.roomId === message.room_id) {
        const cache = chatQueryClient.getQueryData(['messages', chatParams])
        const messageInCurrentRoom =
          message.room_id === currentRoom?.room_id && !message.thread_parent?.id
        const messageInCurrentThread =
          message.room_id === currentRoom?.room_id &&
          message.thread_parent?.id === chatParams.threadParentId

        switch (type) {
          case 'chat.message.deleted':
            setMessageQueryCache((cache: any) =>
              removeMessage({ cache, message })
            )
            break
          case 'chat.message.created':
            // update the message if it exists via optimistic update
            if (hasMessage({ cache, newMessage: message })) {
              setMessageQueryCache((cache: any) =>
                updateMessage({ cache, newMessage: message })
              )
            } else {
              if (allowNotifications) {
                notify({
                  title: `Fable: New message from ${message.user?.display_name}`,
                  body: message.text || '',
                })
              }

              if (messageInCurrentRoom || messageInCurrentThread) {
                // Notifies ChatWindow of latest new message for notifications
                newMessage.current = message
                // or append the new message
                setMessageQueryCache((cache: any) =>
                  appendMessage({
                    cache,
                    newMessage: message,
                  })
                )
              }
            }

            break
          case 'chat.message.updated':
            setMessageQueryCache((cache: any) =>
              updateMessage({
                cache,
                newMessage: message,
              })
            )
        }

        debounce(messagesQuery.refetch, 500)
      } else {
        // Calls to get updated unread counts
        debounce(milestoneDiscussionsQuery.refetch, 500)
      }
    }

    return () => {
      setAblyMessage(null)
    }
  }, [
    ablyMessage,
    chatParams,
    messagesQuery,
    milestoneDiscussionsQuery.refetch,
    chatQueryClient,
    setMessageQueryCache,
    currentRoom?.room_id,
    allowNotifications,
    notify,
  ])

  useEffect(() => {
    if (!!clubSlug && !clubDetailsQuery.isFetched) {
      clubDetailsQuery.refetch()
    }
  }, [clubDetailsQuery, clubSlug])

  return {
    error,
    chatQueryClient,
    user,
    clubBookId,
    clubId,
    messagesQuery,
    messageSingleQuery,
    milestoneDiscussionsQuery,
    milestonesQuery,
    clubMembersQuery,
    clubDetailsQuery,
    clubBookQuery,
    newMessage: newMessage.current,
    modal,
    defaultQueryOptions,
    openedThreads,
    currentRoom,
    chatParams,
    image,
    setImage,
    closeThread,
    setModal,
    setClubSlug,
    enterRoom,
    sendMessage: sendMessage.mutate,
    deleteMessage: deleteMessage.mutate,
    sendReaction: sendReaction.mutate,
    deleteReaction: deleteReaction.mutate,
  }
}

export default useChat
