import { useState } from 'react'
import { css, cx } from '@fable/theme'
import { OptionsMenuItem, OptionsMenuSecondaryAction } from '../types'
import { OptionsMenuControl } from './OptionsMenuControl'
import { OptionsMenuBox } from './OptionsMenuBox'

interface Props extends React.HTMLProps<HTMLDivElement> {
  anchor?: 'top' | 'bottom'
  className?: string
  containerClass?: string
  control?: boolean
  controlDirection?: 'horizontal' | 'vertical'
  customControl?: React.ReactNode | string
  hover?: boolean
  items: OptionsMenuItem[]
  secondaryAction?: OptionsMenuSecondaryAction
  width?: string
  centered?: boolean
}

const OptionsMenu: React.VFC<Props> = ({
  anchor = 'bottom',
  className,
  containerClass,
  control,
  controlDirection = 'vertical',
  customControl,
  hover = false,
  items,
  secondaryAction,
  width,
  centered = false,
  ...nativeProps
}) => {
  const [open, setOpen] = useState(false)

  const onOpenOptionsMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (!control) return

    e.stopPropagation()
    if (!open) setOpen(true)
  }

  const onCloseOptionsMenu = () => {
    if (open) setOpen(false)
  }

  const renderControl = () => {
    if (customControl) {
      return (
        <div
          className={cx(
            hover &&
              css`
                &:hover + .fds-ui--dropdown-box {
                  opacity: 1;
                  pointer-events: auto;
                  transform: ${centered
                    ? 'translate(-50%, 0)'
                    : 'translateY(0)'};
                }
              `
          )}
        >
          {customControl}
        </div>
      )
    }

    return (
      control && (
        <OptionsMenuControl
          onClick={onOpenOptionsMenu}
          direction={controlDirection}
        />
      )
    )
  }

  return (
    <div
      {...nativeProps}
      className={cx(
        css`
          position: relative;
          width: ${customControl ? 'auto' : '30px'};
          height: ${customControl ? 'auto' : '30px'};
          pointer-events: auto;
        `,
        containerClass
      )}
    >
      {renderControl()}
      <OptionsMenuBox
        className={className}
        items={items}
        onClose={onCloseOptionsMenu}
        open={open}
        secondaryAction={secondaryAction}
        anchor={anchor}
        hover={hover}
        width={width}
        centered={centered}
      />
    </div>
  )
}

export default OptionsMenu
