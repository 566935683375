import { dialog } from '@microsoft/teams-js'
import getHost from 'utils/getHost'

const invokeTask = ({
  title,
  slug,
  height = 656,
  width = 600,
  onSuccess,
}: {
  title: string
  slug: string
  height?: number
  width?: number
  onSuccess?: () => void
}) => {
  dialog.url.open(
    {
      title,
      url: `${getHost()}/${slug}${window.location.search}`,
      size: {
        height,
        width,
      },
    },
    async (result) => {
      if (result.err) return console.error(result.err)
      if (onSuccess) onSuccess()
    }
  )
}

export default invokeTask
