import { useContext } from 'react'
import { useTheme, css } from '@fable/theme'
import { Button, Text } from '@fluentui/react-northstar'
import { ChatContext, Message } from '@fable/chat'
import { FlexBox } from '@fable/components'
import { useTeams } from 'hooks/useTeams'

const ChatPaneMessageDeleteModal = ({ message }: { message?: Message }) => {
  const { colors } = useTheme()
  // TypeScript only throws errors for this in Teams, not in our web app
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { deleteMessage, setModal } = useContext(ChatContext)
  const { theme } = useTeams()

  if (!message) return null

  const handleClickDelete = () => {
    deleteMessage(message)

    setTimeout(() => {
      setModal({ show: false })
    }, 200)
  }

  return (
    <div
      className={css`
        width: fit-content;
        max-width: 300px;
        background: ${colors.whiteFang};
        border-radius: 10px;
      `}
    >
      <div
        className={css`
          padding: 20px;
          text-align: center;
        `}
      >
        <FlexBox
          flexDirection="column"
          centerAll
          className={css`
            gap: 10px;
          `}
        >
          <Text
            size="large"
            weight="bold"
            color={theme.siteVariables.bodyColor}
          >
            Are you sure you want to delete this message?
          </Text>
          <Text size="large" color={theme.siteVariables.bodyColor}>
            This cannot be undone
          </Text>
        </FlexBox>
        <div
          className={css`
            margin-top: 20px;
          `}
        >
          <Button
            fluid
            styles={{
              background: 'red',
              color: 'white',
              marginBottom: '10px',
            }}
            onClick={handleClickDelete}
          >
            Delete
          </Button>
          <br />
          <Button fluid text onClick={() => setModal({ show: false })}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChatPaneMessageDeleteModal
