import { get, post, put } from '@fable/api'
import { Book, Club } from '@fable/types'
import { UseQueryOptions } from 'react-query'

export const defaultQueryOptions: Omit<
  UseQueryOptions<any, unknown, any, any>,
  'queryKey' | 'queryFn'
> = {
  keepPreviousData: true,
  refetchInterval: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  enabled: false,
  onError: (err) => console.error(err),
}

export const getClub = async ({
  tid,
  channelId,
}: {
  tid: string
  channelId: string
}) => await get(`/tenants/${tid}/channels/${channelId}/club`)

export const getProfile = async () => await get('/settings/profile/')

export const getClubs = async (userId: string) =>
  await get(`/users/${userId}/clubs?enterprise=true`)

export const getCompanyClubs = async (userId: string) =>
  await get(`/users/${userId}/company/clubs?enterprise=true`)

export const getGenres = async () => await get('/genres/')

export const getBooks = async (term: string, offset: number) =>
  await get(`/search?auto=${term}&type=book&limit=20&offset=${offset}`)

export const createClub = async (args: {
  title: string
  about_us: string
  genre_ids: string[]
  tenant_team_channel_id: string
  tenant_team_id: string
}) => await post('/clubs/', args)

export const addBookToClub = async ({
  club,
  book,
}: {
  club?: Club
  book: Book
}) =>
  put(`/clubs/${club?.id}`, { title: club?.title, current_book_id: book.id })

export const getFolios = async () =>
  get('/folios/?platform=web&include_books=true')

export const getBookDetail = async (isbn: string) => get(`/books/${isbn}`)

export const getBookReviews = async (bookId: string) =>
  get(`/books/${bookId}/reviews/`)

export const getCustomToken = async (accessToken: string) => {
  const result = await post('/post_auth/teams', {
    access_token: accessToken,
  })
  return result.data.custom_token
}

export const createMilestoneWithWizard = async ({
  clubId,
  clubBookId,
  body,
}: {
  clubId: string
  clubBookId: string
  body: {
    start_date: string
    num_milestones: number
    duration_in_days: number
  }
}) => post(`/clubs/${clubId}/club_books/${clubBookId}/milestones/wizard`, body)

// Comment out until UI is ready
// export const createMilestoneManually = async ({
//   clubId,
//   clubBookId,
//   body,
// }: {
//   clubId: string;
//   clubBookId: string;
//   body: {
//     end_chapter_id: string;
//     date: string;
//     note: string;
//     type: string;
//   };
// }) => post(`/clubs/${clubId}/club_books/${clubBookId}/milestones`, body);
