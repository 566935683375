import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChatContext, ChatMessages, ChatInput } from '@fable/chat'
import { css, cx } from '@fable/theme'
import ChatPaneMessage from 'components/chat/ChatPaneMessage'
import ChatPaneActionBar from 'components/chat/ChatPaneActionBar'
import { FlexBox } from '@fable/components'
import { Button, ChevronStartIcon, Text } from '@fluentui/react-northstar'
import { useTeams } from 'hooks/useTeams'

const ChatPaneWindow = ({
  isThread,
  className,
}: {
  isThread?: boolean
  className?: string
}) => {
  const navigate = useNavigate()
  const { theme } = useTeams()
  const { currentRoom, chatParams, closeThread, openedThreads } =
    // TypeScript only throws errors for this in Teams, not in our web app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useContext(ChatContext)

  const openedThread = (openedThreads || [])[0] || {}
  const { threadParentId } = openedThread
  const { grey } = theme.siteVariables.colorScheme

  const handleClickCloseThread = () => {
    closeThread()
    navigate(-1)
  }

  return (
    <FlexBox
      // It's reversed so as the ChatInput container expands while typing, it expands upwards
      flexDirection="column-reverse"
      className={cx(
        css`
          height: 100%;
        `,
        className
      )}
    >
      <div
        className={css`
          flex: 1 0 auto;
          border-top: 1px solid ${grey.foreground};

          * {
            outline: none;
          }
          .chat-input {
            display: flex;
            padding: 19px 0;
            margin: 0 15px;
            width: calc(100% - 30px);
            background: transparent;
          }
          .chat-input-editable-wrapper {
            color: ${grey.foreground};
            border: 1px solid ${grey.foreground};
            border-radius: 3px;
            height: fit-content;
            width: 218px;
            flex: 1 0 75%;
          }
        `}
      >
        <ChatInput
          dimensions={{
            minHeight: 34,
            maxHeight: 100,
            paddingTop: 6,
            paddingLeft: 9,
          }}
          actionBarComponent={<ChatPaneActionBar />}
        />
      </div>
      <ChatMessages
        className={css`
          flex: 1 1 auto;
          border: none;
          border-radius: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          > .chat-message {
            width: 100%;
          }
        `}
        roomId={chatParams?.roomId || ''}
        messageComponent={<ChatPaneMessage />}
        newMessageFlag="new message"
        thread={isThread ? openedThread : undefined}
      />
      {threadParentId && (
        <div
          className={css`
            justify-content: flex-start;
            margin-top: 12px;
          `}
        >
          <Button text onClick={handleClickCloseThread}>
            <ChevronStartIcon color={grey[750]} />
            <div
              className={css`
                margin-left: 2px;
              `}
            >
              <Text color={grey[750]} size="large" weight="semibold">
                {currentRoom?.title}
              </Text>
            </div>
          </Button>
        </div>
      )}
    </FlexBox>
  )
}

export default ChatPaneWindow
