import { firebaseAuth, get, setAuthToken } from '@fable/api'
import { ChatContextProvider } from '@fable/chat'
import { css } from '@fable/theme'
import { Button, Loader } from '@fluentui/react-northstar'
import { TeamsUserCredential } from '@microsoft/teamsfx'
import { PublicClientApplication, LogLevel } from '@azure/msal-browser'
import { useMachine } from '@xstate/react'
import ClubBookTask from 'components/club_book_task/ClubBookTask'
import ClubBookTaskDetail from 'components/club_book_task/ClubBookTaskDetail'
import MilestoneTask from 'components/milestone_task/MilestoneTask'
import ConnectToFableTask from 'components/sign_in_task/ConnectToFableTask'
import SignInTask from 'components/sign_in_task/SignInTask'
import { useTeams } from 'hooks/useTeams'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Route, Routes } from 'react-router-dom'
import { ClubContextProvider } from 'utils/ClubContext'
import { defaultQueryOptions, getCustomToken, getProfile } from 'utils/query'
import { MachineContext, machine } from './App.Machine'
import ChannelTab from './ChannelTab'
import ClubSetting from './ClubSetting'
import {
  getFirebaseUser$,
  linkToFirebaseToMicrosoft,
  scopes,
  signInWithFirebaseCustomToken,
  waitForAccountLinked,
} from './Firebase'
import YourClubs from './YourClubs'
import FindClubs from './FindClubs'
import { useMachineLogger } from './hooks/useMachineLogger'

const App = () => {
  const { context: teamsContext, inTeams, error: teamsError } = useTeams()

  const userQuery = useQuery('user', async () => await getProfile(), {
    ...defaultQueryOptions,
  })

  const clientType = teamsContext?.app.host.clientType
  const tid = teamsContext?.user?.tenant?.id
  const clientId = process.env.REACT_APP_CLIENT_ID || ''

  const teamsUserCredential = useMemo(() => {
    const credential = new TeamsUserCredential({
      initiateLoginEndpoint: '/auth-start.html',
      clientId: process.env.REACT_APP_CLIENT_ID,
    })

    if (clientType === 'ios') {
      // monkeypatch TeamsUserCredential to use localStorage rather than sessionStorage for iOS
      // @ts-ignore
      const oldInit = credential.init
      // @ts-ignore
      credential.init = async function () {
        await oldInit.apply(credential)
        // @ts-ignore
        credential['msalInstance'] = new PublicClientApplication({
          auth: {
            clientId: clientId,
            authority: `https://login.microsoftonline.com/${tid}`,
          },
          cache: {
            cacheLocation: 'localStorage',
          },
          system: {
            loggerOptions: {
              logLevel: LogLevel.Verbose,
              loggerCallback: (level, message, containsPii) => {
                if (console.hasOwnProperty('re')) {
                  // @ts-ignore
                  console.re.info(message)
                } else {
                  console.log(message)
                }
              },
              piiLoggingEnabled: true,
            },
          },
        })
      }
    }
    return credential
  }, [clientType, tid, clientId])

  const [state, send, service] = useMachine(machine, {
    services: {
      fetchMicrosoftAccessToken: async () =>
        teamsUserCredential.getToken(scopes),
      signInWithMicrosoft: () => teamsUserCredential.login(scopes),
      fetchCustomToken: async (ctx: MachineContext) => {
        if (!ctx.accessToken) {
          throw new Error('Expected access token')
        }
        return await getCustomToken(ctx.accessToken)
      },
      getFirebaseUser: getFirebaseUser$,
      signInWithFirebase: (ctx: MachineContext) => {
        if (!ctx.customToken) {
          throw new Error('Expected customToken')
        }
        return signInWithFirebaseCustomToken(ctx.customToken)
      },
      fetchFableUser: async () => {
        const result = await userQuery.refetch()
        if (result.error) {
          throw result.error
        }
        return result.data
      },
      linkMicrosoftAndFirebase: async () => {
        if (!firebaseAuth.currentUser) {
          throw new Error('Missing Firebase user')
        }
        if (teamsContext?.app.host.clientType === 'web') {
          await linkToFirebaseToMicrosoft(firebaseAuth.currentUser)
        } else {
          const result = await get('/auth/token')
          const accountLinkingUrl = new URL(
            `/link-to-microsoft/?token=${result.data.auth_token}`,
            window.location.href
          ).href
          window.open(accountLinkingUrl)
          await waitForAccountLinked()
        }
      },
    },
    actions: {
      updateAxiosToken: (ctx: MachineContext) => {
        if (!ctx.idToken) {
          throw new Error('Expected idToken')
        }
        setAuthToken(ctx.idToken)
      },
    },
  })

  useMachineLogger(service)

  useEffect(() => {
    if (inTeams && tid && clientType && state.matches('idle')) {
      send('START')
    } else {
      console.log('WAITING', inTeams, state.value, tid, clientType)
    }
  }, [inTeams, state, send, tid, clientType])

  const error = state.context.error || teamsError

  if (error) {
    return (
      <div
        className={css`
          max-width: 400px;
          margin: 20px auto;
          text-align: center;
        `}
      >
        <h1>Something went wrong</h1>
        <h3>Detailed error message:</h3>
        <p>{error.message}</p>
        <Button content="Try again" onClick={() => send({ type: 'RETRY' })} />
      </div>
    )
  }

  if (state.matches('promptForMicrosoftInteractiveAuth')) {
    return <SignInTask onSignInClick={() => send('SIGN_IN_WITH_MICROSOFT')} />
  } else if (state.matches('promptForAccountLink')) {
    return (
      <ConnectToFableTask
        onConnectClick={() => send('LINK_MICROSOFT_AND_FIREBASE')}
      />
    )
  }

  const user = userQuery?.data?.data

  return (
    <div>
      {state.matches('authenticated') ? (
        <ChatContextProvider user={user}>
          <ClubContextProvider>
            <Routes>
              <Route
                path="/club-setting"
                element={
                  <ClubSetting
                    postAuthCalled={!!state.context.customToken}
                    accessToken={state.context.accessToken}
                  />
                }
              />
              <Route path="/club/*" element={<ChannelTab />} />
              <Route path="/add-book" element={<ClubBookTask />} />
              <Route path="/add-book/:id" element={<ClubBookTaskDetail />} />
              <Route path="/create-milestone" element={<MilestoneTask />} />
              {/* comment out until UI is ready */}
              {/* <Route path="/create-milestone/manual" >
                    <MilestoneTaskManual />
                  </Route> */}
              <Route
                path="/tabs/your-clubs"
                element={<YourClubs user={user} />}
              />
              <Route
                path="/tabs/find-clubs"
                element={<FindClubs user={user} />}
              />
            </Routes>
          </ClubContextProvider>
        </ChatContextProvider>
      ) : (
        <Loader color="#eeeeee" style={{ height: '100vh', width: '100%' }} />
      )}
    </div>
  )
}

export default App
