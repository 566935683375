import { memo, useContext } from 'react'
import { cx, css, useTheme } from '@fable/theme'
import { ChatContext } from '../chat_context'
import { Message } from '../chatTypes'

const ChatMessageReplyButton = memo(
  ({
    message,
    children,
    disabled,
  }: {
    message: Message
    children: React.ReactChild
    disabled?: boolean
  }) => {
    const { nativeOverrides } = useTheme()
    const { enterRoom, currentRoom } = useContext(ChatContext)

    if (disabled) {
      // As of July 2022, the backend does not support nested replies

      return null
    }

    return (
      <button
        onClick={() =>
          enterRoom({
            room: currentRoom,
            threadParent: message,
          })
        }
        className={cx(
          nativeOverrides.buttonClear,
          css`
            cursor: pointer;
            width: fit-content;
            height: fit-content;
            * {
              cursor: pointer;
            }
          `
        )}
      >
        {children}
      </button>
    )
  },
  (prevProps, nextProps) => prevProps.message.id === nextProps.message.id
)

export default ChatMessageReplyButton
